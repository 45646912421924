$color-principal: #ff8533;
$color-principal2: #434341;
$verd: #50BD86;
$blau: #4AB3E5;
$lila: #9164CD;
$gris-clar: #F4F4F4;
$gris-mig: #E6E6E4;
$gris-fosc: #AAAAA9;
.container-left {
    > div,
    ion-grid {
        margin-right: 3px;
        border-bottom: 2px solid $gris-mig;
    }
}

ion-col.left {
  // padding-right: 0;
  // padding-top: 0;
  padding: 0;
  background-color: white;
  border-right: 2px solid $gris-mig;
}

ion-row {
  height: auto;
}

@media (max-width: 770px){
  .logotipo{
    max-height: 65% !important;
  }
}