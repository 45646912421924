@media (max-width: 1250px) {
  .modal.modal_copy .modal_content {
    left: 15vw;
    width: 70vw;
  }

  .container-left {
    margin: 0 5px;
  }
  .container-left a {
    font-size: 1.1rem;
  }
  .container-left h4 {
    font-size: 1.9rem;
  }
  .container-left .marginElementos {
    margin-left: 15px;
  }

  ion-button.timelineBtn {
    --padding-top: 15px;
    --padding-end: 15px;
    --padding-bottom: 15px;
    --padding-start: 15px;
  }

  .modal.modal_export .modal_content {
    width: 40vw;
    left: 30vw;
  }

  h2 {
    font-size: 3rem;
  }

  h4 {
    font-size: 2rem;
  }

  .recuadroLogin .login-row .login-button {
    margin-top: 20px;
  }

  .btn-enabled-principal, .btn-disabled-principal {
    padding: 8px 12px;
  }

  .registerPage ion-item::part(native) {
    --min-height: 30px;
    height: 40px;
    border-radius: 10px;
  }
  .registerPage ion-item.destacadoCheck::part(native) {
    padding-left: 0;
  }
  .registerPage .item-native {
    height: 30px;
  }

  ion-avatar .avatar-small {
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
  }

  .acompanyant ion-avatar {
    width: 61px;
    height: 61px;
  }

  .btn-actividades {
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .actividades-btns.lineal-flex {
    display: block;
  }
}
@media (max-width: 1024px) {
  ion-item.commentItem {
    width: 75%;
    margin-left: 0;
  }
}
@media (max-width: 992px) {
  ion-img.muyGrande {
    width: 4.5rem;
    height: 4.5rem;
  }

  ion-alert .alert-head h2 {
    line-height: 2.5rem !important;
  }
  ion-alert .alert-wrapper {
    --width: 85vw;
  }
  ion-alert.select-alert.multiple-select-alert {
    --max-width: 80vw;
  }
  ion-alert.select-alert .alert-wrapper {
    width: 80vw;
    --max-width: 80vw;
    height: fit-content;
  }
  ion-alert.select-alert .alert-wrapper .alert-checkbox-group {
    --max-height: fit-content;
  }

  .actividades-defecto-comunitecaa > .container {
    width: 85%;
  }

  .right > .container {
    width: 85%;
  }

  .modal_export .modal_content,
* .modal_content {
    left: 5vw;
    width: 90vw;
    padding: 15px 25px;
  }

  .btn-naranja {
    width: 5vh;
    height: 5vh;
  }

  .btn-objetivos {
    padding: 10px 15px;
  }
  .btn-objetivos.selected {
    padding-right: 15px;
  }

  ion-button p {
    font-size: 1.4rem;
  }
  ion-button.cancelBtn {
    --padding-top: 10px;
    --padding-end: 15px;
    --padding-bottom: 10px;
    --padding-start: 15px;
    height: fit-content;
  }
  ion-button.saveBtn {
    --padding-top: 10px;
    --padding-end: 15px;
    --padding-bottom: 10px;
    --padding-start: 15px;
    height: fit-content;
  }

  .interiorEtapa .etapa {
    max-height: fit-content;
  }
  .interiorEtapa .etapa .borde-etapa {
    max-height: fit-content !important;
  }

  .btn-recursos,
.btn-objetivos,
.btn-actividades {
    padding: 5px 10px !important;
    font-size: 1.5rem;
  }
}
@media (max-width: 770px) {
  .container-left {
    margin: 0 5px;
  }
  .container-left a {
    font-size: 1.3rem;
  }
  .container-left h4 {
    font-size: 2.5rem;
  }
  .container-left .marginElementos {
    margin-left: 15px;
  }

  ion-toolbar.header h3 {
    font-size: 27px;
  }

  html {
    font-size: 8px;
  }

  .right > .container, .actividades-defecto-comunitecaa > .container {
    width: 90%;
  }

  .titulo-seccion {
    font-size: 1.4rem;
  }

  .btn-objetivos {
    padding: 8px 15px !important;
  }

  .btn-recursos {
    padding: 8px 10px !important;
  }
}
@media (max-width: 425px) {
  .scrollable-content > .container {
    width: 90%;
  }

  .popover-content p {
    padding-top: 5px;
    line-height: 22px;
    margin-bottom: 10px;
  }

  .nuevoUsuario .btn-close {
    top: -5%;
  }
  .nuevoUsuario .cnt-usuari ion-img {
    height: 65px !important;
    width: 65px !important;
    margin: auto;
  }

  .vertical-line2 {
    left: 2.1rem;
    top: 6rem;
  }

  .cnt-vertical-line .vertical-line {
    left: 15px;
  }

  .modal_add .modal_content {
    padding: 15px 40px;
    height: fit-content;
  }
  .modal_add ion-grid {
    height: auto;
  }

  .modal_modify .modal_content, .modal_notificaciones .modal_content, .modal_add .modal_content, .modal_roles .modal_content, .modal_activity_detail .modal_content {
    width: 90vw !important;
    left: 5vw !important;
  }

  ion-alert .alert-head {
    display: none;
  }
  ion-alert .alert-radio-label.sc-ion-alert-md {
    white-space: normal !important;
  }
  ion-alert.select-alert .alert-wrapper {
    width: 90vw;
    --max-width: 90vw;
  }

  .select-interface-option {
    min-height: 55px;
  }

  a {
    font-size: 1.6rem;
  }

  ion-content[fullscreen=true].canvi-bg-mvl.bg-gris-clar {
    background-color: white;
  }

  .small {
    font-size: 1.4rem;
  }

  ion-select::part(text) {
    font-size: 1.4rem;
  }

  #nuevaPublicacion {
    padding: 5px 5px;
  }
  #nuevaPublicacion .nuevaPubliBottom {
    padding-left: 10px;
  }

  .nuevaPublicacion {
    padding: 5px 5px;
  }

  .export-button {
    width: 65%;
  }

  .recuadroLogin {
    margin: 0;
  }

  .btn-enabled-third {
    padding: 8px 15px;
  }
  .btn-enabled-third.btn-auxiliar {
    position: absolute;
    bottom: 35px;
  }

  .right .activities-main .actividad {
    min-height: 15vh;
  }

  .act-obj-copia .row-competencies .buttons-scroll {
    top: 22vh;
  }

  .clipped-1-line {
    width: 52%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    background: transparent;
    position: initial;
  }

  ion-toolbar h4 {
    font-weight: bold;
    font-size: 2.8rem;
  }
  ion-toolbar h4,
ion-toolbar h3 {
    width: 50vw;
  }

  #nuevaPublicacion {
    margin-top: 3vh !important;
  }

  p {
    font-size: 1.9rem;
  }

  p.body2 {
    font-size: 1.7rem;
  }

  .modal.modal_import .modal_content {
    left: 5vw;
    width: 90vw;
  }
  .modal.modal_objetivos .modal_content {
    left: 5vw;
    width: 90vw;
  }
  .modal.modal_export .modal_content {
    top: 10vh;
    left: 5vw;
    width: 90vw;
  }
  .modal.modal_copy .modal_content {
    top: 10vh;
    left: 5vw;
    width: 90vw;
  }

  input.native-input.sc-ion-input-ios {
    padding-left: 10px;
  }

  ion-img.empty-img::part(image) {
    width: 40%;
    margin: auto;
  }
  ion-img.muyGrande {
    width: 4.5rem;
    height: 4.5rem;
  }

  ion-item.commentItem {
    width: 75%;
  }

  .nuevoUsuario .inputRedondeado {
    margin-left: 0;
  }

  .row-competencies {
    min-height: 7rem;
  }
  .row-competencies .buttons-scroll {
    padding-right: 5%;
    left: 0px;
    padding-bottom: 5px;
    overflow-x: scroll;
  }

  .interior::before {
    left: 17px;
  }

  html {
    font-size: 7.5px;
  }

  .dot {
    height: 20px;
    width: 20px;
  }

  .fullSize .iconPrincipal button ion-icon {
    height: 10rem;
    width: 10rem;
  }

  .roundColumn {
    height: 65px;
    width: 65px;
  }

  .fullSize > ion-grid {
    width: 100vw;
  }

  .mvl {
    display: block !important;
  }
  .mvl.timelineBtn {
    display: inline-block !important;
  }

  #onesignal-bell-launcher {
    display: none;
  }

  ion-avatar {
    height: fit-content;
  }
  ion-avatar .avatar-principal {
    width: 40px;
    height: 40px;
  }

  .desktop {
    display: none;
  }
  .desktop.timelineBtn {
    display: none;
  }
  .desktop.d-flex {
    display: none !important;
  }

  .right {
    padding-bottom: 40px;
  }

  .fullSize ion-grid .usuaris img {
    width: 6rem;
    height: 6rem;
  }
  .fullSize ion-grid .usuaris h4 {
    margin: 0px 5px !important;
    font-size: 17px;
  }



  ion-tab-bar.mvl {
    display: flex !important;
  }

  ion-tabs {
    height: 100vh !important;
  }

  ion-icon.muyGrande {
    height: 3.5rem;
    width: 3.5rem;
  }

  ion-buttons ion-button {
    height: 7rem !important;
  }
  ion-buttons ion-button.export-button {
    height: fit-content !important;
    width: 100%;
  }

  ::slotted(ion-buttons) {
    min-height: 50px;
  }

  .publicacion {
    margin-bottom: 3rem !important;
  }
  .publicacion .comentarios {
    padding-left: 12%;
  }

  .actividadGrupos .circleImgGrande {
    height: 55px;
    width: 55px;
  }
  .actividadGrupos .publicacion {
    margin: 0;
  }

  .modal.modal_modify .modal_content {
    padding: 20px;
  }

  img.circleImg {
    height: 6rem;
    width: 6rem;
  }

  .btn-disabled-principal {
    height: 4.5rem;
  }
  .btn-objetivos {
    padding: 6px 10px !important;
  }
  .btn-recursos {
    padding: 6px 10px !important;
  }

  ion-button.cancelBtn {
    --padding-top: 10px;
    --padding-end: 15px;
    --padding-bottom: 10px;
    --padding-start: 15px;
    height: fit-content;
  }
  ion-button.saveBtn {
    --padding-top: 10px;
    --padding-end: 15px;
    --padding-bottom: 10px;
    --padding-start: 15px;
    height: fit-content;
  }
}
@media (max-width: 425px) {
  ion-content[fullscreen=true].canvi-bg-mvl.bg-gris-clar.bg-fosc {
    background-color: #F4F4F4 !important;
  }
}
@media (max-width: 425px) {
  .achievement-logo .achievement-logo-width {
    width: 65%;
  }
}
@media (max-width: 768px) {
  .modal.modal_copy .modal_content, .modal.modal_modify .modal_content {
    top: 20vh;
  }
}