$color-principal: #FF8533;
$color-principal2: #434341;
$verd: #50BD86;
$blau: #4AB3E5;
$lila: #9164CD;
$gris-fons: #F4F4F4;
$gris-suau: #E6E6E4;
$gris-mig: #AAAAA9;

#skeletonHeader{
  --background-rgb: 231,149,201;
  border: 1px solid white;
}


@import url('https://fonts.googleapis.com/css2?family=Rambla&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

@font-face {
  font-family: "Closer Font";
  src: url("../styles/fonts/CloserText-Light.otf") format('opentype');
  font-weight: 200;
}
@font-face {
  font-family: "Closer Font";
  src: url("../styles/fonts/CloserText-Regular.otf") format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: "Closer Font";
  src: url("../styles/fonts/CloserText-Medium.otf") format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: "Closer Font";
  src: url("../styles/fonts/CloserText-SemiBold.otf") format('opentype');
  font-weight: bold;
}

// ion-header {
//   min-height: 65px;
// }

* {
  user-select: text;
}
.ion-color-warning{
  --ion-color-warning: #FF8533 !important;
}

ion-avatar {
  width: 64px;
    height: 64px;
}
.mvl {
  display: none !important;
}

.desktop {
  &.timelineBtn {
    display: inline-block;
  }
  display: block;
}
.desktop.ion-page{
  position: relative;
  height: 100%;
}
.hidden{
  display: none !important;
}

html {
  font-size: 10px;
}


::-webkit-scrollbar,
*::-webkit-scrollbar {
  // display: none;
}



*.capitalize{
  text-transform: capitalize;
}

.forget-password-page,
.registerPage{
  margin-top: 50px;
  margin-bottom: 50px;
  .bg-white{
    border-radius: 16px;
  }
  a{
    color: $color-principal !important;
  }
}


ion-content[fullscreen=true] {
  --offset-bottom: auto !important;
  --overflow: hidden;
  overflow: auto;

  &::-webkit-scrollbar {
    // display: none;
  }

  &.bg-grey {
    background-color: #E5E5E5 !important;
  }
  &.bg-white {
    background-color: #FFFFFF !important;
  }

  &.bg-gris-clar {
    background-color: #F4F4F4 ;
  }
}


body,
h1,
h2,
h3,
h4,
h5 {
  font-family: "Closer Font";
}

h1 {
  font-weight: 600;
  font-size: 6rem;
  line-height: 8rem;
}

h2 {
  font-weight: 400;
  font-size: 4.6rem;
  line-height: 6.3rem;
}

h3 {
  font-weight: 500;
  font-size: 3.4rem;
  line-height: 4.5rem;
  text-transform: none;
}

h4 {
  font-weight: 400;
  font-size: 2.2rem;
  line-height: 3.2rem;
}

h5 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.7rem;
}

*.handCursor:hover {
  cursor: pointer;
}

.sendIcon {
  position: absolute;
  right: 0;
}

#video {
  width: 600px;
  max-width: 100%
}

.avatarGrande {
  height: 65px !important;
  width: 65px !important;
}

.m-auto {
  margin: auto;
}




.big-textarea textarea.native-textarea{
  height: 30vh !important;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  background: white;
  padding: 15px;
  font-size: 16px;
  font-family: "Closer Font", sans-serif;
}

.fill-space {
  width: -webkit-fill-available;
  padding: 30px;
}

.row-competencies {
  .buttons-scroll {
    .btn-objetivos:not(.selected) {
      background-color: transparent;
      &.selected{

      }
    }
  }
}


a{
  &.text-lila{
    color: $lila !important;
  }
  &.text-verd {
    color: $verd !important;
  }
  &.text-blau {
    color: $blau !important;
  }
}
.html-description{
  span{
    font-size:inherit;
  }
}
.strong {
  font-weight: bold !important;
}
ion-input, ion-textarea{
  font-family: "Closer Font" !important;
}
.black {
  color: black;
}
ion-input, ion-textarea,
span,
p {
  font-family: "Closer Font";
  margin-bottom: 0;
  font-size: 1.6rem;
  line-height: 2.2rem;

  &.okButton {
    margin: auto;
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 600;
  }

  &.overline {
    font-family: 'Rambla', sans-serif;
    font-size: 1.2rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.2rem;
  }

  &.body1 {
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-weight: 400;
    color: black;
  }

  &.subtitle2 {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    size: 1.4rem;
    line-height: 2.4rem;
  }

  &.titulo-seccion {
    font-family: 'Rambla', sans-serif;
    font-size: 1.6rem;
    line-height: 2.0rem;
    text-transform: uppercase;
    color: white;
  }

  &.noAplica {
    font-family: "Closer Font";
    margin-bottom: 0;
    font-size: 1.2rem;
    line-height: 1.5rem;

    background: gray;
    padding: 0 10px;
    border-radius: 4px;
    font-weight: 100;
    color: white !important;

  }

  &.finalizado,
  &.text-verd {
    color: $verd !important;

    .arrowColored {
      path {
        stroke: $verd;
      }
    }
  }

  &.text-lila {
    color: $lila !important;

    .arrowColored {
      path {
        stroke: $lila;
      }
    }
  }

  &.text-blau {
    color: $blau !important;

    #coloredArrow {
      stroke: $blau !important;
    }
  }

  &.text-gris {
    color: $gris-mig !important;
  }



  &.pendiente {
    color: black !important;
  }
}

p,
a {
  &.text-naranja {
    font-family: "Closer Font";
    color: $color-principal !important;
  }
}
.post {
  a {
    color: $blau;
    text-decoration: underline;
  }
}
a {
  cursor: pointer !important;

  &.no-link {
    cursor: auto !important;
  }
}

h4 {
  &.text-lila {
    color: $lila !important;
  }

  &.text-blau {
    color: $blau !important;
  }

  &.text-verd {
    color: $verd !important;
  }

  &.text-gris {
    color: $gris-mig;
  }
}

.titulo-seccion {
  font-family: 'Rambla', sans-serif;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.0rem;
  text-transform: uppercase;
  letter-spacing: 0.185em;
}
ion-textarea#postText{
  max-height: 80px;
  min-height: 45px;
  .textarea-wrapper{
    padding: 10px 10px 10px 8px;
    textarea{
      height: auto;
    padding: 0;
    } 
  }
} 
ion-textarea.comment-input {
  
  .textarea-wrapper{
    height: 40px;
    padding: 10px 5px 5px 8px;
    textarea{
      height: auto;
    padding: 0;
    }
  }
}

ion-input, ion-textarea,
p,
span,
a {
  &.body2 {
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: black;
    font-weight: 400;
  }

}

a,
button {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.8rem;
  text-decoration: none;
}

.btn-minusculas {
  p {
    text-transform: none;
  }
}



.publiRecurso {
  border: 2px solid #B6DCEF;
}

.publiActividad {
  border: 2px solid #C8B8DA;
}

.publiObjetivo {
  border: 2px solid #B9DAC9;
}

.sinElementos {
  margin-left: 15px;
  border: 1px dashed #AAAAA9;
  box-sizing: border-box;
  border-radius: 17px;
  padding: 20px;
  margin-bottom: 10px;
}


ion-buttons {
  z-index: inherit !important;
  ion-button {
    height: fit-content !important;
    --background-hover: transparent !important;
  }
}

.no-break {
  white-space: nowrap;
}


.content-height{
  height: calc(100vh - 68px);
}
.container-left {
  margin-left: 15px;
  padding-right: 15px;
  .sinElementos{
    a{
      p.body1{
        font-size: 1.4rem;
      }
    }
  }
  overflow-y: scroll;
  height: calc(100vh - 68px);
  padding-bottom: 20px;

  // &::-webkit-scrollbar {
  //   display: none;
  // }
}

#bannerEdicion {
  background-color: #FFB4E6;
  height: 30px;
  visibility: hidden;

  &.edicion {
    visibility: visible;
  }
}


.right {
  padding: 0;
  background-color: $gris-fons;
  overflow-y: scroll;
  height: 93.35vh;

  // &::-webkit-scrollbar {
  //   display: none;
  // }
  & > div, & > ion-grid {
    padding-bottom: 30px;
  }

  &>.container {
    width: 70%;
  }

  .actividad {
    min-height: 115px;
  }
  &-0{
    position: absolute;
    right:0;
  }
}
.container-left {
  .actividad {
    min-height: 70px;
  }
}


.align-center {
  align-items: center;
}

ion-toggle{
  --handle-width: 18px;
  --handle-height: 18px;
  height: 20px;
  width: 40px;
}

/******** TAMAÑO GRID ********/

.toolbar-container{
  height: 100%;
}
ion-toolbar {
  --background: $color-principal2;
  h4{
    color: white;
    text-transform: none;
    font-weight: 200;
  }
  h4,h3{
    text-align: left;
  }
}
ion-skeleton-text{
  margin: 5px;
}
ion-icon,
img {
  &.img-avatar{
    width:66px;
    height:66px;
  }
  &.preview{
    max-height: 40vh;
  }

  &.center {
    margin: auto;
    display: flex;
  }

  &.medio {
    min-height: 2rem;
    min-width: 2rem;
  }

  &.grande {
    min-height: 3rem;
    min-width: 3rem;
  }

  &.grande3rem {
    min-height: 3rem;
    min-width: 3rem;
  }

  &.muyGrande {
    margin: auto;
    height: 4.5rem;
    width: 4.5rem;
  }

  &.extraGrande {
    margin: auto;
    height: 6rem;
    width: 6rem;
  }
  &.logoComunite{
    width: 20%;
  }
  &.logoOrange{
    width: 50%;
  }

  &.reversed {
    transform: rotate(180deg);
  }
}


.roundColumn {
  text-align: center;
  border-radius: 999px;
  border: 1px solid $gris-mig;
  height: 90px;
  width: 90px;
  margin: auto;
}


/******** MENU LATERAL ********/

.menu-lateral{

  &-objetivos, &-recursos, &-actividades, &-acompañantes{
    h4 {
      margin-bottom: 0 !important;
    }
    .objetivo{
      padding-top: 20px;
    }
    .objetivo:first-child{
      padding-top: 0;
    }
    padding-bottom: 35px;

    ion-progress-bar{
      width: 75%;
    }

    .actividades, .recursos, .acompañantes {
      > ion-col {
        padding-top: 15px;
      }
    }
  }
}
.objetivos {
  h4 {
    color: $verd
  }
}

.objetivo {
  margin-left: 27px;

  p, span {
    margin: 3px 0;

    &.overline {
      color: $verd;
    }
  }

  ion-progress-bar {
    --background: #50BD864d;
    --progress-background: #50BD86;
    height: 8px;
    border-radius: 10px;
  }
}

.actividades {
  h4 {
    color: $lila;
  }
}

.select-activities-caa{
  ion-radio {
    --color: transparent;
    --color-checked: transparent;
    // display:none;
  }
}
ion-col{
  .actividadCAA{
    &.actividadCAA{
      padding: 10px;
      &.selected {
        border: 3px solid #FF8533;
      }
    }
  }
}
.fabAdd{
  position: absolute;
  right: 10px;
  bottom: 0px;
}
.actividad {
  border: 1px solid #AAAAA9;
  box-sizing: border-box;
  border-radius: 13px;
  margin-right: 10px;
  background-color: white;
  padding: 0 2rem 1rem 1.5rem;
  .activity-text {
    width: 100%;
  }

}

.h{
  &-3rem{
    height: 3rem;
  }
}


.recursos {
  h4 {
    color: $blau;
  }
}

.acompañantes {
  h4 {
    color: $color-principal2;
  }
}

.acompanyant{
  img{
    &.photo{
      border: 1px solid gray;
    }
  }
  .body2::first-letter{
    text-transform: uppercase;
  }
}
.acompanyant-lateral{
  ion-avatar{
    width: 63px;
    height: 63px;
  }
}

.objetivos,
.acompañantes,
.actividades,
.recursos {
  display: flex;

  a {
    color: $color-principal;
    margin-top: 16px;
    margin-bottom: 10px;
    margin-left: auto;
  }
}

/******** MENU LATERAL BIBLIO ********/
.lateral-biblio {
  h4{
    margin: 0;
  }

  .actividades,
  .objetivos,
  .recursos {
    h4 {
      color: $gris-mig;
    }
  }
}

/**********   ESTILO GENERALES  *********/

.rowTitulo {
  min-height: 85px;
}

.lineal {
  display: inline;

  button {
    margin: 0 5px 5px 0;
  }
}
.lineal-flex {
  display: inline-flex !important;

  button {
    margin: 0 5px 5px 0;
  }
}

.col-ajustada {
  width: auto;
}

ion-content {
  --offset-bottom: 0 !important;
}

ion-content[fullscreen=true] {
  background-color: white;
}

.progress{
  width: 75%;
  border-radius: 10px;
  background-color: #50BD864d;
  .progress-bar {
    background-color: #50BD86;
  }
}
ion-progress-bar {
  --background: #50BD864d;
  --progress-background: #50BD86;
  height: 8px;
  border-radius: 10px;

}

ion-col {
  padding-left: 0 !important;

  &.contImg {
    max-width: fit-content !important;

    ion-icon {
      width: 30px;
      height: 30px;
    }
  }
}

ion-backdrop {
  background: #00000014 !important;
}

.popoveCustom .popover-viewport.sc-ion-popover-md {
  margin-top: 0;
  margin-right: 0;
}
.popover-viewport.sc-ion-popover-md {
  border: 1px solid #999;
  padding: 10px 20px;
}

.popover-content {
  padding: 15px;
  overflow: visible !important;

  &.sc-ion-popover-md {
    background: transparent;
  }

  ion-label {
    &.sc-ion-select-popover {
      margin-right: 15px;
    }
  }

  a {
    font-weight: normal;
    cursor: pointer !important;
    // line-height: 42px;

  }

  p {
    padding-top: 5px;
    line-height: 42px;
  }
}
[aria-checked=true].sc-ion-alert-md .alert-radio-icon.sc-ion-alert-md {
  border-color: $color-principal;
}
.alert-radio-inner.sc-ion-alert-md {
  background-color: $color-principal;
}
.alert-message {
  h3{
    color: black;
  }
}

.select-text{
  font-size: 1.6rem;
}
ion-alert {
  &.select-alert{
    &.multiple-select-alert{
      --max-width:40vw;
    }
    .alert-wrapper{
      width:50vw;
      --max-width:50vw;
      height:fit-content;
      .alert-checkbox-group{
        --max-height:fit-content;
      }
    }

  }

  &.multiple-select-alert {
    --max-width: 700px;
    --width: 600px;
  }

  .alert-checkbox-label {
    font-family: "Closer Font";
    white-space: break-spaces !important;
    font-weight: 500;
  }

  .alert-wrapper {
    padding: 15px 20px;
    --max-width: 420px;

  }

  .alert-button-group {
    justify-content: center !important;

    button {
      span {
        color: white;
        text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.8rem;
      }

      &:nth-child(1) {
        background: $gris-mig;
        border-radius: 21px;
        padding: 10px 25px;

        &:hover {
          background: #BDBDBD;
        }

        &:focus {
          background: #E0E0E0;
        }
      }

      &:nth-child(2) {
        background: $color-principal;
        border-radius: 21px;
        padding: 10px 25px;

        &:hover {
          background: #FFA970;
        }

        &:focus {
          background: #FFC19F;
        }
      }
    }
  }



  [aria-checked="false"] {
    .alert-checkbox-label {
      color: #737373;
    }
  }
}


.marginElementos {
  margin-left: 25px;
}

button {
  background-color: transparent;
}

/**** HOME ****/

// Componente NuevaPublicacion
#nuevaPublicacion {
  background-color: white;
  border-radius: 12px;
  border: 2px solid $color-principal;
  padding: 5px 20px;

  .inputTexto {
    border: 1px solid #E0E0E0;
    border-radius: 20px;

  }

  .nuevaPubliTop {
    border-bottom: 1px solid #E6E6E4;
  }

  .nuevaPubliBottom {
    padding-bottom: 10px;

    p {
      // margin-left: 10px;
    }
  }

  .btn-enabled-principal {
    height: 4rem;

    p {
      color: $color-principal;
    }
  }

  .btn-disabled-principal {
    height: 4rem;

    p {
      color: black;
    }
  }

  p {
    color: $color-principal;
  }


}


.publicar {
  background-color: $color-principal;
  color: white;
  border-radius: 21px;
}

.button-native {
  --background: transparent !important;
}

.split-left-btn {
  border-radius: 21px 0 0 21px !important;
  margin-right: 1px;
  padding: 10px 15px 10px 20px !important;
}

.split-right-btn {
  border-radius: 0 21px 21px 0 !important;
  padding: 0 13px !important;
}

.btn-filter-home {
  border: 1px solid #888888;
  padding: 5px 6px;
  border-radius: 5px;

  &.selected {
    background-color: $color-principal;
    padding: 5px 6px !important;
  }
}

.btn-auxiliar {
  position: absolute;
  bottom: 45px;
  border: 1x solid black;
}

.btn-reacciones {
  position: absolute;
  right: 0;
  bottom: 100%;
  &.show{
    display: flex !important;
  }
}

.btn-enabled-third {
  border: 1px solid #AAAAA9;
  background: white;
  --background: #FFFFFF;
  --box-shadow: none !important;
  --background-hover: #FFFFFF;
  border-radius: 21px;
  padding: 10px 25px;
  margin-top: 15px;
  display: none;
  width: fit-content;
  z-index: 99;

  p {
    color: $color-principal;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.8rem;
    margin-left: 0rem !important;
  }

  &.show {
    display: block;
  }

}

.btn-enabled-principal {
  background: $color-principal;
  --background: #FF8533;
  --box-shadow: none !important;
  --background-hover: #FFA970;
  border-radius: 21px;
  padding: 10px 25px;
  // margin-top: 15px;

  &:hover {
    background: #FFA970;
    --background: #FFA970;
    --background-hover: #FFA970;
  }

  &:focus {
    background: #FFC19F;
    --background: #FFC19F;
  }

  p {
    color: white !important;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.8rem;
    margin-left: 0rem !important;
  }
}



.btn-disabled-principal {
  background: #C4C4C4;
  border-radius: 21px;
  padding: 10px 25px;
  // margin-top: 15px;

  p {
    color: #7D7D7D;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.8rem;
  }
}


.btn-enabled-secundario {
  background: $gris-mig;
  border-radius: 21px;
  padding: 10px 25px;
  margin-top: 15px;

  &:hover {
    background: #BDBDBD;
  }

  &:focus {
    background: #E0E0E0;
  }

  p {
    color: white;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.8rem;
  }
}


.btn-disabled-secundario {
  background: #C4C4C4;
  border-radius: 21px;
  padding: 10px 25px;
  margin-top: 15px;

  p {
    color: #7D7D7D;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.8rem;
  }
}

.comentario {
  ion-avatar{
    min-width: 65px;
    width: 65px;
  }
}
// Componente NuevoComentario
#nuevoComentario {
  .inputTexto {
    border: 1px solid #E0E0E0;
    border-radius: 20px;
  }
}

// PUBLICACIONES
.publicacion {
  background-color: white;
  border-radius: 12px;

  video {
    max-height: 45vh;
    background: black;
  }

  p{
    user-select: text;
  }

  .comentarios {
    padding-left: 5%;
    // border-left: 1px solid $gris-mig;
  }
}




//**** OBJETIVOS ****//
.btns-title{
  height: 5vh;
}
.btn-naranja {
  height: 5vh;
    width: 5vh;
  background-color: $color-principal;
  border-radius: 5px;
  padding: 5px 0;
}

.borde-objetivo {
  border-bottom: 1px solid $verd;
}

.btn-objetivos {
  background-color: white;
  color: $gris-mig;
  &.selected {
    background: $verd;
    border: none;
    color: white;
    padding: 5px 10px;
  }
  &:hover:not(.selected){
    background: $verd !important;
    border-color: transparent;
    color: white;
    opacity: 0.55;
  }

  &.titulo-seccion {
    // color: $gris-mig;

    &.selected {
      color: white;
      padding: 5px 10px;
    }
  }

  padding: 5px 10px;
  text-align: center;
  border: 1px solid #AAAAA9;
  border-radius: 21px;

}

// etapas
.etapa p.text-titol:hover {
  color: $verd !important;
}

.etapa {

  width: 100%;

  p {
    color: black;
  }

  .status {
    &-0{
      color:black;
    }
    &-1{
      font-weight: bold;
    }
  }

  .borde-etapa {
    border-bottom: solid 1px #BDBDBD;
  }

  ion-progress-bar {
    width: 75%;
  }
}
.etapa{
  &.border-gray {
    border-bottom: solid 1px #BDBDBD;
  }
}
.interiorEtapa {
  // border-left: #BDBDBD 1px solid;
  // margin-left: 19px;
  padding-left: 25px;

  .etapa {
    // max-height: 5vh;

    .borde-etapa {
      // min-height: 5vh;
      // max-height: 5vh;
    }
  }
}

// nuevo objetivo

ion-item {
  --border-radius: 5px;
  --border-color: #C4C4C4;
  --border-style: solid;
  --border-width: 1px;
  --background-hover: transparent;
  --background-focused: transparent;
  --highlight-color-focused: #FF8533;
  --highlight-color-valid:#FF8533;
  --highlight-color-invalid:#FF8533;

  &.objetivos-checkbox {
    --border-color: transparent;
    --background: transparent;
    --background-hover: transparent;
    --background-focused: transparent;
  }
}

ion-select {
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  &::part(text) {
    font-size: 1.5rem;
  }
}
ion-checkbox {
  --background-checked: #FF8533;
  --border-color-checked: #FF8533;
}
ion-radio{
  --color-checked:#FF8533;
}


ion-radio-group {
  ion-item {
    --background: transparent;
    --border-color: transparent;
  }
}

ion-item {
  &.destacadoCheck {
    --background: transparent;
    --border-color: transparent;
  }
}

ion-button {
  --background: transparent;
  --box-shadow: none;
}

ion-button {
  p {
    font-family: "Closer Font";
    font-weight: 600;
    // color: white !important;
    margin-left: 0 !important;
    size: 1.4rem !important;
    line-height: 1.8rem;
    // padding: 1.3rem 2.4rem;
  }

  &.saveBtn {
    color: white;
    --padding-top: 20px;
    --padding-end: 25px;
    --padding-bottom: 20px;
    --padding-start: 25px;
    --background: #FF8533;
    --border-radius: 21px;
    --box-shadow: none;
  }

  &.cancelBtn {
    color: white;
    --padding-top: 20px;
    --padding-end: 25px;
    --padding-bottom: 20px;
    --padding-start: 25px;
    --background: #AAAAA9;
    --border-radius: 21px;
    --box-shadow: none;
  }
  &.timelineBtn {
    --padding-top: 20px;
    --padding-end: 25px;
    --padding-bottom: 20px;
    --padding-start: 25px;
    --background: #fff;
    --border-radius: 21px;
    --border-style:  solid;
    --border-width: 1px ;
    --border-color:  #FF8533;
    --box-shadow: none;
    --color: #FF8533;
  }

  &.imagenBtn {
    --padding-top: 20px;
    --padding-end: 25px;
    --padding-bottom: 20px;
    --padding-start: 25px;
    --background: transparent;
    --border-radius: 21px;
    --box-shadow: none;
    --border-style: solid;
    --border-color: #AAAAA9;
    --border-width: 1px;

    p {
      color: $gris-mig;
    }
  }
}

.imagenBtn {
  margin-right: 10px;
  background: transparent;
  &.btn-objetivos {
    justify-content: end;
  }
  p {
    color: $gris-mig;
  }
}





//**** ACTIVIDADES ****//
.borde-actividad {
  border-bottom: 1px solid $lila;
}

.btn-new {
  &:hover{
    color: white !important;
    p{
      color: white !important;
    }
  }
  
}
.btn-actividades {
  color: $gris-mig;
  &:hover:not(.selected) {
    background: $lila;
    border-color: transparent;
    color: white;
    opacity: 0.55;
  }

  &.selected {
    background: $lila;
    border-color: transparent;
    color: white;
    padding: 5px 10px;
  }

  // &.titulo-seccion {
  //   color: $gris-mig;

  //   &.selected {
  //     color: white;
  //   }
  // }

  padding: 5px 10px;
  text-align: center;
  border: 1px solid #AAAAA9;
  border-radius: 21px;
}

.status {
  &-0 {
    p {
      color: black !important;
    }
  }
  &-1 {
    p {
      font-weight: bold;
    }
  }
}
.pendiente{
  p {
    color: $gris-mig !important;
  }
}


.terminada {
  p {
    color: $lila !important;
  }

  .actividad {
    border-color: $lila !important;
  }
}




//****** PAGINA PRINCIPAL ******//

ion-grid {
  --ion-grid-padding: 0px;
}

.fullSize {
  // width: 80%;
  align-self: center;

  ion-col {
    align-items: center;
  }

  h3 {
    color: black;
  }

  .iconPrincipal {
    text-align: right;

    button {
      background-color: transparent;

      ion-icon {
        height: 15vh;
        width: 15vh;
      }
    }
  }

  &>ion-grid {
    width: 70vw;
  }


  ion-grid {
    .usuaris {
      ion-avatar{
        &.photo{
          ion-img {border: 1px solid $gris-mig;}
        }
      }
      img {
        width: 70px;
        height: 70px;
        border-radius: 99px;
        // &.photo{border: 1px solid $gris-mig;}

      }

      h4 {
        margin: 0 10px;
      }

      .usuari {
        border-top: 1px solid #FF8533;
      }

      &:last-of-type {
        border-bottom: 1px solid #FF8533;
      }
    }
  }

  ion-router-link {
    --color: black;
  }
}

/*******  RECURSOS *******/
.borde-recursos {
  border-bottom: 1px solid $blau;
}

.btn-recursos {
  color: $gris-mig;

  &:hover:not(.selected){
    background: $blau !important;
    border-color: transparent;
    color: white;
    opacity: 0.55;
  }

  &.selected {
    background: $blau;
    border: none;
    color: white;
    padding: 5px 10px;
  }

  &.titulo-seccion {
    margin: 0 5px 5px 0;
    &.selected {
      color: white;
    }
  }

  padding: 5px 10px;
  text-align: center;
  border: 1px solid #AAAAA9;
  border-radius: 21px;

}

.recurso {
  border-bottom: 1px solid #BDBDBD;

  &.lastRecurso {
    border-bottom: none;
  }
}


img.circleImg {
  border-radius: 99px;
  height: 5vh;
  width: 5vh;
}

img.circleImgGrande {
  border-radius: 99px;
  height: 65px;
  width: 65px;
}


/* PRINCIPAL */
.nuevoUsuario,
.actividadGrupos {

  &.circleImg,
  &.circleImgGrande {
    border: 1px solid #AAAAA9;
    box-sizing: border-box;
    border-radius: 999px;
  }

  .inputRedondeado {
    // margin-left: 30px;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 16px;
  }
}

.actividadGrupos {
  margin: 5vh 0;
  .inputRedondeado {
    margin-left: 30px;
  }

  .publicacion {
    margin: 0 10%;
    margin-bottom: 2rem !important;
  }
}

/* BIBLIOTECA */
  .sc-ion-popover-md-h{
    --box-shadow: none !important;
  }


.selected {
  padding-right: 20px;

  &.cont-activid {
    border-bottom: 2px solid $lila;

    .h4 {
      color: $lila !important;
    }
  }

  &.cont-obj {
    border-bottom: 2px solid $verd;

    .h4 {
      color: $verd !important;
    }
  }

  &.cont-recurso {
    border-bottom: 2px solid $blau;

    .h4 {
      color: $blau !important;
    }
  }
}

.selector-biblioteca {
  border-bottom: 2px solid #c4c4c4;
}

span {
  &.actividad {
    min-height: 80px;
  }
}

.avatar-group{
  min-width: 64px;
}
ion-avatar{
  .avatar-small{
    min-width: 55px;
    min-height: 54px;
    width: 55px;
    height: 54px;
  }
}
.header{
  ion-avatar{
    width: 4.5rem;
    height: 4.5rem;
    min-height: 45px;
    min-width: 45px;
  }
  img{
    &.photo{
      border: 2px solid white;
    }
  }
}
/* LOGIN */
.headerLogin {
  border-bottom: 1px solid $color-principal;
  margin: 10px;
  padding: 15px;
  padding-left: 0 !important;
  margin-left: 0 !important;
}

.squareBorder {
  background-color: $gris-mig;
  height: 100%;
}


.separator {
  border-bottom: 1px solid $gris-mig;
}

.border-bottom {
  border-bottom: 1px solid #BDBDBD;
}


// test modal popup notificaciones
.modal {
  display: block;
  position: fixed;
  z-index: 4;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  .item {
    ion-label {
      text-overflow: unset;
      white-space: unset;
    }
  }
  ion-item {
    --border-style: none !important;
  }
  &.modal_notificaciones {
    .modal_content {
      overflow: auto;
      width: 60vw;
      left: 20vw;
    }
  }
  &.modal_photo{
    .modal_content {
      position: relative;
      width: fit-content;
      margin: auto;
      top: 10vh;
      left: 0;
      height: 85vh;
      img{
        height: 100%;
        // width: inherit;
      }
    }
  }
  &.modal_aviso{
    // background-color: transparent;
  }
  &.modal_activity_detail{
    z-index: 99999999;
    .modal_content{
      min-height: 70vh;
      top: 15vh;
      width: 60vw;
      left: 20vw;
      
    }
  }
  &.modal_aviso{
    // position: absolute;
    .modal_content{
      height: auto;
      overflow-y: hidden;
      background-color: #FFFFCC;
      border: 1px solid $gris-mig;
      position: fixed;
      width: 80vw;
      left: 10vw;
      top: 5%;
      p{
        margin-left: 5vw;
      }
    }
  }

  &.modal_modify {
    input {
      padding: 0 !important;
    }

    .modal_content {
      overflow: hidden;
      width: 60vw;
      left: 20vw;
      padding: 55px;
    }
  }
  &.modal_objetivos{
    .modal_content{
      top: 10vh;
      width: 80vw;
      left: 10vw;
      height: fit-content;
    }
  }

}

.modal_content {
  background-color: white;
  position: absolute;
  min-height: 50vh;

  top: 25vh;
  overflow-y: scroll;


  // top: 30vh;
  width: 80vw;
  left: 10vw;
  padding: 20px;
  border-radius: 3px;
  border: 1px solid #C4C4C4;
}

.close {
  color: Black;
  float: right;
}

.close:hover {
  color: cyan;
  cursor: pointer;
}

.modal_copy {
  .modal_content {
    // top: 35vh;
    left: 30vw;
    width: 40vw;
  }
}
.modal_export {
  .modal_content {
    top: 25vh;
    left: 30vw;
    width: 40vw;
  }
}
.modal_import{
  .modal_content {
    top: 25vh;
    left: 30vw;
    width: 40vw;
  }
}
.modal_roles{
  .roleRow{
    border-bottom: 1px solid #BDBDBD;
  }
}
.modal_add{
  ion-grid, .modal_content{
    height: 30vh;
    top: 35vh;
    overflow: hidden;
    border-bottom: none !important;
  }
}
.modal_roles, .modal_add {
  .modal_content {
    padding: 30px 55px;
    width: 50vw;
    left: 25vw;
  }

  .roleRow {
    padding: 10px;

    .text-right {
      text-align: right;
    }
  }

  ion-item {
    border: 1px solid #C4C4C4;
    border-radius: 5px;
  }
}

.invisible {
  display: none;
}


.tab-selected {
  &.tab-home {
    background-color: #FF8533;

    ion-icon {
      content: url('../img/iconos/home_selected.svg');
    }
  }

  &.tab-objetivos, &.tab-biblio-objetivo {
    background-color: #50BD86;

    ion-icon {
      content: url('../img/iconos/object_selected.svg');
    }
  }

  &.tab-actividades, &.tab-biblio-actividad {
    background-color: #9164CD;

    ion-icon {
      content: url('../img/iconos/activ_selected.svg');
    }
  }

  &.tab-recursos, &.tab-biblio-recursos {
    background-color: #4AB3E5;

    ion-icon {
      content: url('../img/iconos/resource_selected.svg');
    }
  }

  &.tab-acompañantes {
    background-color: #434341;

    ion-icon {
      content: url('../img/iconos/companion_selected.svg');
    }
  }
}

ion-tab-button {
  ion-icon {
    font-size: 35px;
  }
}
ion-tabs {
  display: contents !important;
}

.recuadroLogin {
  margin: 125px 0;
  background-color: white;
  border-radius: 16px;
  .register-button {
    height: 47px;
    --background: #50BD86;
    background: #50BD86;

    &:hover {
      background: #6FCF97;
      --background: #6FCF97;
      --background-hover: #6FCF97;
    }
  }

  .login-row {
    .login-button {
      margin-top: 38px;
      width: 100%;
      height: 47px;
    }

    padding-bottom: 30px;
  }
}
.loginPage, .loginPageMvl{
  img{
    max-width: 40%;
  }
  * {
    color: #434341;
  }
}
.loginPageMvl{
  margin-top: 10vh;
}
div{
  &.commentItem {
    display: inline-flex;
    align-items: center;
    .comment-icon{
      z-index: 999999;
      // position: absolute;
      right: 5px;
    }
  }
}
.chincheta-home {
  height: 20px;
  width: 20px;
}
ion-item, div {
  &.commentItem {
    margin-left: 5px;
    --border-style: none;
    width: 100%;
    border-radius: 20px;
    border: 1px solid #E0E0E0;
    .comment-icon{
      z-index: 999999;
      // position: absolute;
      right: 5px;
    }
  }
  &.commentItem::part(native) {
    height: fit-content;
    --min-height: none;
  }

}


.accordion {
  cursor: pointer;
  padding: 10px;
  width: 100%;
  border: none !important;
  text-align: left;
  outline: none;
  transition: 0.4s;
  margin: 0;

}
// .accordion:after {
//   content: '\002B';
//   color: #777;
//   font-weight: bold;
//   float: right;
//   margin-left: 5px;
// }

// .active:after {
//   content: "\2212";
// }

.ml-auto {
  margin-left: auto;
}
.panel {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  &.show{
    max-height: fit-content;
  }
  .etapa-descripcion{
    margin: 15px 0;
  }
}
.panel-interior{
  display: none;
  &.show{
    display: block;
  }
}



.tr-absolute{
  margin: 5px;
  position: absolute;
  top: 0;
  right: 0;
  display: inline;
}
.contenidorEtapa{
  background-color: white;
  border-radius: 16px ;
}

.achievement-logo{
  margin: -1px !important;
  border-radius: 8px 8px 0 0;
  .achievement-logo-width{
    width: 75%;
  }
}
.objective-achievement{
  &.achievement-logo{
    background-color: #50BD86;
  }
  border: 3px solid #50BD86;
  // b{
  //   color: $verd;
  // }
}
.resource-achievement{
  &.achievement-logo{
    background-color: #4AB3E5;
  }
  border: 3px solid #4AB3E5;
  // b{
  //   color: $blau;
  // }
}

p {
  &.resource-achievement{
    border: none;
    b{
      color: $blau;
      text-decoration: underline;
    }
  }
  &.activity-achievement{
    border: none;
    b{
      color: $lila;
      text-decoration: underline;
    }
  }
  &.objective-achievement{
    border: none;
    b{
      color: $verd;
      text-decoration: underline;
    }
  }
}
.activity-achievement{
  &.achievement-logo{
    background-color: #9164CD;
  }
  border: 3px solid #9164CD;
  // b{
  //   color: $lila;
  // }
}
.dot {
  height: 25px;
    width: 25px;
    background-color: #ff8533;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    bottom: 1vh;
}
ion-row{
  &.etapa-titulo:last-child{
    .vertical-line {
      display: none;
    }

  }
}
.vertical-line2 {
  width: 1px; /* Line width */
  background-color: #aaaaa9; /* Line color */
  // height: 80%; /* Override in-line if you want specific height. */
  left: 2rem;
  top: 5.3rem;
  position: absolute;
  bottom: -15px;
  &.show {
    bottom: 0;
  }

}
// .vertical-line2.show{
//   width: 1px; /* Line width */
//   background-color: #aaaaa9; /* Line color */
//   // height: 80%; /* Override in-line if you want specific height. */
//   left: 2rem;
//   top: 5.5rem;
//   position: absolute;
//   bottom: 0;
// }
// .cnt-vertical-line {
//   .vertical-line{
//     height: 50%;
//     border-left: 1px solid $gris-mig;
//     margin-top: 5px;
//     left: 20px;
//     position: absolute;
//   }

// }

.cnt-input-name{
  width: 75%;
  margin: auto;
}

.interior{
  img{
    align-self: start;
    position: relative;
    top: 5px;
  }
}

.border-none{
  border: none !important;
}
.border-gris{
  border: 1px solid $gris-mig;
}


.activity-clipped-text{
  width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    background: transparent;
    position: initial;
}
.activity-clipped-text-small{
  width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    background: transparent;
    position: initial;
}
.activity-clipped-text-1{
  width: 100%;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    background: transparent;
    position: initial;
}


.group-selector{
  img{
    visibility: hidden;
  }
  &.selected{
      color: $color-principal;
      img{
        visibility: visible;
      }
  }
}
.edit-remove-container{
  position:absolute;
  bottom: 0;
  ion-icon{
    margin: 0 0.5vh;
    &.grande{
      min-height: 3rem;
      min-width: 3rem;
    }
  }
}
.cardEmptyElement{
  .bg-white{
    border-radius: 16px;
    // height: 20vh;
  }
}
.infoPost{
  background-color: white;
  border-radius: 25px;
  margin: 5px 10px
}

:root{
  --ion-font-family: "Closer Font";
}

ion-toolbar{
  .toolbar-container {
    height: 100%;
  }

  &.header{
    button:first-child {
      align-items: center;
      width: 100%;
      display: inline-flex;
      ion-avatar{
        margin: 5px
      }
    }
  }
}

.icon-inner {

}


.preview-image {
  max-width: 35%;
}
.preview-video {
  max-width: 45%;
}
.icon-accordion {
  width: 15px;
  height: 20px;
  margin-right: 2%;
}
.text-titol {
  width: 97%;
}
.text-descripcion {
  font-size: 1.4rem;
  line-height: 1.6rem;
}

.actividades-defecto-comunitecaa{
  min-height: 100%;
  background-color: #E0E0E0;
  .desktop-stiky{
    position: sticky;
    top: 0;
    z-index: 4;
  }
  .actividadCAA {
    position: relative;
  }
  .fabAdd {
    position: absolute;
    right: 20px;
    bottom: 20px;
    margin: 0;
    top: unset;
  }

  &>.container {
    width: 70%;
  }
  .rowTitulo {
    padding-top: 15px;
  }

  .btn-objetivos:not(.selected){
    background-color: #F4F4F4 !important;
  }


  ion-select {
    color: $lila;
    font-weight: bold;
    // font-size: 12px;
    &::part(icon) {
      opacity: 1;
    }
  }

  .cnt-vertical-line {
    .vertical-line.show {
      height: 90%;
    }
  }

}


.cnt-detalle {
  margin-top: 10px;
  background-color: white;
  border-radius: 15px;
  padding: 15px 20px;
}

.btn-salir {
  padding: 5px 10px;
    width: fit-content;
    background: white;
    border: 1px solid $color-principal;
    border-radius: 20px;
  p {
    color: $color-principal;
  }
}
.cnt-recurso {
  min-height: 57px;
  margin: 5px 0;
  padding: 5px 10px;
  background-color: white;
  position: relative;
  width: 100%;
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  a{
    width: 85%;
  }
  ion-icon{
    &.grande {
      min-height: 2.5rem;
      min-width: 2.5rem;
    }
  }
  .tr-absolute {
    align-items: center;
    height: -webkit-fill-available;
  }
}
.menuLateral{
  &-recurso{
    width: 100%;
    display: flex;
    align-items: center;
  }
}



.cnt-usuari {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.nuevoUsuario{
  position: relative;
  .btn-close{
    position: absolute;
    top: 0;
    right: 0
  }

}

.icons-vertical{
  justify-items: center;
  height: fit-content;
  ion-icon {
    margin-bottom: 5px;
  }
}

.cnt-blanc {
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  .titol {
    border-bottom: 1px solid $color-principal;
    margin-bottom: 10px;
  }
}


.modal_notificacion_aviso{
  z-index: 9999999999;
  
}

.aviso-naranja {
  color: $color-principal;
}

.small {
  font-size: 1.2rem;
}
.aviso-naranja {
  &.small {
    color: red;
    font-size: 1.2rem;
  }
}

.cnt-admin-login{
  background: #ffffff;
  padding: 40px 40px 10px;
  border-radius: 4px;
  box-shadow: 0 -25px 37.7px 11.3px rgb(8 143 220 / 7%);
}
.form-group {
  margin-bottom: 1rem;
  label {
    font-size: 13px;
    line-height: 1;
    vertical-align: top;
  }

}


// HOVER EFFECTS //
.popup-select p:hover{ 
  color: $color-principal;
  // transform: scale(1.05) translate(0%);
}
.bigger-hover:hover {
  transform: scale(1.05);
}
.pag-principal {
  .usuari:hover {
    background-color: #43434121;
  }
}

.boxActividad:hover {
  cursor: pointer;
  transform: scale(1.05) translate(0%);
  p {
    // transform: scale(1.1) translate(0%);
  }
}

.menu-lateral-acompañantes {
  border-bottom: none !important;
}

.no-wrapped {
  white-space: normal;
}


.infinite-loading{
  margin-top: 20px;
  margin-bottom: 20px;
}
.scrollable-content {
  height: calc(100vh - 68px);
  overflow: scroll;
  overflow-x: hidden;
}
.scrollable-content > .container {
  width: 70%;
}

.no-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.custom-btn {
  width: fit-content;
}

.custom-alert {
  .custom-btn {
    margin-bottom: 5px;
  }
  .alert-object-too, .alert-activity{
    background: #FF8533;
    border-radius: 21px;
    padding: 10px 25px;
  }
  .alert-cancel {
    background: #AAAAA9;
    border-radius: 21px;
    padding: 10px 25px;
  }
  .alert-object-too:hover, .alert-activity:hover {
    background: #FFA970;
  }
  .alert-cancel:hover {
    background: #E0E0E0;
  }

  .alert-button-group {
    display: inline-flex;
    flex-direction: row;
  }
}
