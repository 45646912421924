.navbar.default-layout {
  font-family: "Poppins", sans-serif;
  background: #444;
  transition: background 0.25s ease;
  -webkit-transition: background 0.25s ease;
  -moz-transition: background 0.25s ease;
  -ms-transition: background 0.25s ease;
}
.navbar.default-layout .navbar-brand-wrapper {
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease;
  background: #ffffff;
  width: 255px;
  height: 63px;
}
.navbar.default-layout .navbar-brand-wrapper .navbar-brand {
  width: 100%;
  color: #ffffff;
  font-size: 1.5rem;
  line-height: 48px;
  margin-right: 0;
  padding: 0.25rem 0;
  display: flex;
}
.navbar.default-layout .navbar-brand-wrapper .brand-logo-mini {
  display: none;
}

.navbar.default-layout .navbar-menu-wrapper {
  transition: width 0.25s ease;
  -webkit-transition: width 0.25s ease;
  -moz-transition: width 0.25s ease;
  -ms-transition: width 0.25s ease;
  color: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  width: calc(100% - 255px);
  height: 63px;
}
.navbar.default-layout .navbar-menu-wrapper h3 {
  color: #ffffff;
}

.navbar.default-layout .navbar-brand-wrapper .brand-logo-mini img {
  height: auto;
  width: 80%;
  margin: 10%;
}

.navbar.default-layout .navbar-brand-wrapper .navbar-brand img {
  height: 100%;
  width: auto;
  margin: auto;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.page-body-wrapper {
  min-height: calc(100vh - 63px);
  display: flex;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
}

.sidebar {
  min-height: calc(100vh - 63px);
  background: #ffffff;
  font-family: "Poppins", sans-serif;
  padding: 0;
  width: 255px;
  z-index: 11;
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease;
}
.sidebar .nav {
  overflow: hidden;
  flex-wrap: nowrap;
  flex-direction: column;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.page-body-wrapper:not(.auth-page) {
  padding-top: 63px;
}

.nav .nav-item, .navbar-nav .nav-item {
  line-height: 1;
}

.sidebar .nav .nav-item .nav-link {
  align-items: center;
  display: flex;
  padding: 16px 35px;
  white-space: nowrap;
  height: 52px;
  color: #4a4a4a;
}

.sidebar .nav .nav-item .collapse {
  z-index: 999;
}

.collapse {
  display: none;
}

.sidebar .nav.sub-menu {
  margin-bottom: 0;
  padding: 0 0 0 4rem;
}

.sidebar .nav {
  overflow: hidden;
  flex-wrap: nowrap;
  flex-direction: column;
}

.sidebar .nav.sub-menu .nav-item .nav-link {
  color: #4a4a4a;
  padding: 0.75rem 1rem;
  font-size: 12px;
  line-height: 1;
  height: auto;
}

.sidebar .nav .nav-item .nav-link {
  align-items: center;
  display: flex;
  padding: 16px 35px;
  white-space: nowrap;
  height: 52px;
  color: #4a4a4a;
}

.nav-link {
  display: unset;
  padding: 0.5rem 1rem;
  color: #4a4a4a;
}
.nav-link:hover {
  color: #4a4a4a;
}

.sidebar .nav .nav-item .nav-link .menu-icon {
  margin-right: 1.25rem;
  width: 16px;
  line-height: 1;
  font-size: 18px;
  color: #979797;
}

.sidebar .nav .nav-item .nav-link .menu-title {
  color: inherit;
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  vertical-align: middle;
}

.main-panel {
  transition: width 0.25s ease, margin 0.25s ease;
  width: calc(100% - 255px);
  min-height: calc(100vh - 63px);
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  background: #f2f8f9;
  padding: 1.5rem 1.7rem;
  width: 100%;
  flex-grow: 1;
}

.sub-menu {
  margin: 20px 0;
  padding: 0 0 0 4rem;
}