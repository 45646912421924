.container-left > div,
.container-left ion-grid {
  margin-right: 3px;
  border-bottom: 2px solid #E6E6E4;
}

ion-col.left {
  padding: 0;
  background-color: white;
  border-right: 2px solid #E6E6E4;
}

ion-row {
  height: auto;
}

@media (max-width: 770px) {
  .logotipo {
    max-height: 65% !important;
  }
}/*# sourceMappingURL=MenuLateral.css.map */