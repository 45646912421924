@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Rambla&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");
#skeletonHeader {
  --background-rgb: 231,149,201;
  border: 1px solid white;
}

@font-face {
  font-family: "Closer Font";
  src: url("../styles/fonts/CloserText-Light.otf") format("opentype");
  font-weight: 200;
}
@font-face {
  font-family: "Closer Font";
  src: url("../styles/fonts/CloserText-Regular.otf") format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: "Closer Font";
  src: url("../styles/fonts/CloserText-Medium.otf") format("opentype");
  font-weight: 500;
}
@font-face {
  font-family: "Closer Font";
  src: url("../styles/fonts/CloserText-SemiBold.otf") format("opentype");
  font-weight: bold;
}
* {
  user-select: text;
}

.ion-color-warning {
  --ion-color-warning: #FF8533 !important;
}

ion-avatar {
  width: 64px;
  height: 64px;
}

.mvl {
  display: none !important;
}

.desktop {
  display: block;
}
.desktop.timelineBtn {
  display: inline-block;
}

.desktop.ion-page {
  position: relative;
  height: 100%;
}

.hidden {
  display: none !important;
}

html {
  font-size: 10px;
}

*.capitalize {
  text-transform: capitalize;
}

.forget-password-page,
.registerPage {
  margin-top: 50px;
  margin-bottom: 50px;
}
.forget-password-page .bg-white,
.registerPage .bg-white {
  border-radius: 16px;
}
.forget-password-page a,
.registerPage a {
  color: #FF8533 !important;
}

ion-content[fullscreen=true] {
  --offset-bottom: auto !important;
  --overflow: hidden;
  overflow: auto;
}
ion-content[fullscreen=true].bg-grey {
  background-color: #E5E5E5 !important;
}
ion-content[fullscreen=true].bg-white {
  background-color: #FFFFFF !important;
}
ion-content[fullscreen=true].bg-gris-clar {
  background-color: #F4F4F4;
}

body,
h1,
h2,
h3,
h4,
h5 {
  font-family: "Closer Font";
}

h1 {
  font-weight: 600;
  font-size: 6rem;
  line-height: 8rem;
}

h2 {
  font-weight: 400;
  font-size: 4.6rem;
  line-height: 6.3rem;
}

h3 {
  font-weight: 500;
  font-size: 3.4rem;
  line-height: 4.5rem;
  text-transform: none;
}

h4 {
  font-weight: 400;
  font-size: 2.2rem;
  line-height: 3.2rem;
}

h5 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.7rem;
}

*.handCursor:hover {
  cursor: pointer;
}

.sendIcon {
  position: absolute;
  right: 0;
}

#video {
  width: 600px;
  max-width: 100%;
}

.avatarGrande {
  height: 65px !important;
  width: 65px !important;
}

.m-auto {
  margin: auto;
}

.big-textarea textarea.native-textarea {
  height: 30vh !important;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  background: white;
  padding: 15px;
  font-size: 16px;
  font-family: "Closer Font", sans-serif;
}

.fill-space {
  width: -webkit-fill-available;
  padding: 30px;
}

.row-competencies .buttons-scroll .btn-objetivos:not(.selected) {
  background-color: transparent;
}
a.text-lila {
  color: #9164CD !important;
}
a.text-verd {
  color: #50BD86 !important;
}
a.text-blau {
  color: #4AB3E5 !important;
}

.html-description span {
  font-size: inherit;
}

.strong {
  font-weight: bold !important;
}

ion-input, ion-textarea {
  font-family: "Closer Font" !important;
}

.black {
  color: black;
}

ion-input, ion-textarea,
span,
p {
  font-family: "Closer Font";
  margin-bottom: 0;
  font-size: 1.6rem;
  line-height: 2.2rem;
}
ion-input.okButton, ion-textarea.okButton,
span.okButton,
p.okButton {
  margin: auto;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 600;
}
ion-input.overline, ion-textarea.overline,
span.overline,
p.overline {
  font-family: "Rambla", sans-serif;
  font-size: 1.2rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.2rem;
}
ion-input.body1, ion-textarea.body1,
span.body1,
p.body1 {
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: 400;
  color: black;
}
ion-input.subtitle2, ion-textarea.subtitle2,
span.subtitle2,
p.subtitle2 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  size: 1.4rem;
  line-height: 2.4rem;
}
ion-input.titulo-seccion, ion-textarea.titulo-seccion,
span.titulo-seccion,
p.titulo-seccion {
  font-family: "Rambla", sans-serif;
  font-size: 1.6rem;
  line-height: 2rem;
  text-transform: uppercase;
  color: white;
}
ion-input.noAplica, ion-textarea.noAplica,
span.noAplica,
p.noAplica {
  font-family: "Closer Font";
  margin-bottom: 0;
  font-size: 1.2rem;
  line-height: 1.5rem;
  background: gray;
  padding: 0 10px;
  border-radius: 4px;
  font-weight: 100;
  color: white !important;
}
ion-input.finalizado, ion-input.text-verd, ion-textarea.finalizado, ion-textarea.text-verd,
span.finalizado,
span.text-verd,
p.finalizado,
p.text-verd {
  color: #50BD86 !important;
}
ion-input.finalizado .arrowColored path, ion-input.text-verd .arrowColored path, ion-textarea.finalizado .arrowColored path, ion-textarea.text-verd .arrowColored path,
span.finalizado .arrowColored path,
span.text-verd .arrowColored path,
p.finalizado .arrowColored path,
p.text-verd .arrowColored path {
  stroke: #50BD86;
}
ion-input.text-lila, ion-textarea.text-lila,
span.text-lila,
p.text-lila {
  color: #9164CD !important;
}
ion-input.text-lila .arrowColored path, ion-textarea.text-lila .arrowColored path,
span.text-lila .arrowColored path,
p.text-lila .arrowColored path {
  stroke: #9164CD;
}
ion-input.text-blau, ion-textarea.text-blau,
span.text-blau,
p.text-blau {
  color: #4AB3E5 !important;
}
ion-input.text-blau #coloredArrow, ion-textarea.text-blau #coloredArrow,
span.text-blau #coloredArrow,
p.text-blau #coloredArrow {
  stroke: #4AB3E5 !important;
}
ion-input.text-gris, ion-textarea.text-gris,
span.text-gris,
p.text-gris {
  color: #AAAAA9 !important;
}
ion-input.pendiente, ion-textarea.pendiente,
span.pendiente,
p.pendiente {
  color: black !important;
}

p.text-naranja,
a.text-naranja {
  font-family: "Closer Font";
  color: #FF8533 !important;
}

.post a {
  color: #4AB3E5;
  text-decoration: underline;
}

a {
  cursor: pointer !important;
}
a.no-link {
  cursor: auto !important;
}

h4.text-lila {
  color: #9164CD !important;
}
h4.text-blau {
  color: #4AB3E5 !important;
}
h4.text-verd {
  color: #50BD86 !important;
}
h4.text-gris {
  color: #AAAAA9;
}

.titulo-seccion {
  font-family: "Rambla", sans-serif;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.185em;
}

ion-textarea#postText {
  max-height: 80px;
  min-height: 45px;
}
ion-textarea#postText .textarea-wrapper {
  padding: 10px 10px 10px 8px;
}
ion-textarea#postText .textarea-wrapper textarea {
  height: auto;
  padding: 0;
}

ion-textarea.comment-input .textarea-wrapper {
  height: 40px;
  padding: 10px 5px 5px 8px;
}
ion-textarea.comment-input .textarea-wrapper textarea {
  height: auto;
  padding: 0;
}

ion-input.body2, ion-textarea.body2,
p.body2,
span.body2,
a.body2 {
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: black;
  font-weight: 400;
}

a,
button {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.8rem;
  text-decoration: none;
}

.btn-minusculas p {
  text-transform: none;
}

.publiRecurso {
  border: 2px solid #B6DCEF;
}

.publiActividad {
  border: 2px solid #C8B8DA;
}

.publiObjetivo {
  border: 2px solid #B9DAC9;
}

.sinElementos {
  margin-left: 15px;
  border: 1px dashed #AAAAA9;
  box-sizing: border-box;
  border-radius: 17px;
  padding: 20px;
  margin-bottom: 10px;
}

ion-buttons {
  z-index: inherit !important;
}
ion-buttons ion-button {
  height: fit-content !important;
  --background-hover: transparent !important;
}

.no-break {
  white-space: nowrap;
}

.content-height {
  height: calc(100vh - 68px);
}

.container-left {
  margin-left: 15px;
  padding-right: 15px;
  overflow-y: scroll;
  height: calc(100vh - 68px);
  padding-bottom: 20px;
}
.container-left .sinElementos a p.body1 {
  font-size: 1.4rem;
}

#bannerEdicion {
  background-color: #FFB4E6;
  height: 30px;
  visibility: hidden;
}
#bannerEdicion.edicion {
  visibility: visible;
}

.right {
  padding: 0;
  background-color: #F4F4F4;
  overflow-y: scroll;
  height: 93.35vh;
}
.right > div, .right > ion-grid {
  padding-bottom: 30px;
}
.right > .container {
  width: 70%;
}
.right .actividad {
  min-height: 115px;
}
.right-0 {
  position: absolute;
  right: 0;
}

.container-left .actividad {
  min-height: 70px;
}

.align-center {
  align-items: center;
}

ion-toggle {
  --handle-width: 18px;
  --handle-height: 18px;
  height: 20px;
  width: 40px;
}

/******** TAMAÑO GRID ********/
.toolbar-container {
  height: 100%;
}

ion-toolbar {
  --background: $color-principal2;
}
ion-toolbar h4 {
  color: white;
  text-transform: none;
  font-weight: 200;
}
ion-toolbar h4, ion-toolbar h3 {
  text-align: left;
}

ion-skeleton-text {
  margin: 5px;
}

ion-icon.img-avatar,
img.img-avatar {
  width: 66px;
  height: 66px;
}
ion-icon.preview,
img.preview {
  max-height: 40vh;
}
ion-icon.center,
img.center {
  margin: auto;
  display: flex;
}
ion-icon.medio,
img.medio {
  min-height: 2rem;
  min-width: 2rem;
}
ion-icon.grande,
img.grande {
  min-height: 3rem;
  min-width: 3rem;
}
ion-icon.grande3rem,
img.grande3rem {
  min-height: 3rem;
  min-width: 3rem;
}
ion-icon.muyGrande,
img.muyGrande {
  margin: auto;
  height: 4.5rem;
  width: 4.5rem;
}
ion-icon.extraGrande,
img.extraGrande {
  margin: auto;
  height: 6rem;
  width: 6rem;
}
ion-icon.logoComunite,
img.logoComunite {
  width: 20%;
}
ion-icon.logoOrange,
img.logoOrange {
  width: 50%;
}
ion-icon.reversed,
img.reversed {
  transform: rotate(180deg);
}

.roundColumn {
  text-align: center;
  border-radius: 999px;
  border: 1px solid #AAAAA9;
  height: 90px;
  width: 90px;
  margin: auto;
}

/******** MENU LATERAL ********/
.menu-lateral-objetivos, .menu-lateral-recursos, .menu-lateral-actividades, .menu-lateral-acompañantes {
  padding-bottom: 35px;
}
.menu-lateral-objetivos h4, .menu-lateral-recursos h4, .menu-lateral-actividades h4, .menu-lateral-acompañantes h4 {
  margin-bottom: 0 !important;
}
.menu-lateral-objetivos .objetivo, .menu-lateral-recursos .objetivo, .menu-lateral-actividades .objetivo, .menu-lateral-acompañantes .objetivo {
  padding-top: 20px;
}
.menu-lateral-objetivos .objetivo:first-child, .menu-lateral-recursos .objetivo:first-child, .menu-lateral-actividades .objetivo:first-child, .menu-lateral-acompañantes .objetivo:first-child {
  padding-top: 0;
}
.menu-lateral-objetivos ion-progress-bar, .menu-lateral-recursos ion-progress-bar, .menu-lateral-actividades ion-progress-bar, .menu-lateral-acompañantes ion-progress-bar {
  width: 75%;
}
.menu-lateral-objetivos .actividades > ion-col, .menu-lateral-objetivos .recursos > ion-col, .menu-lateral-objetivos .acompañantes > ion-col, .menu-lateral-recursos .actividades > ion-col, .menu-lateral-recursos .recursos > ion-col, .menu-lateral-recursos .acompañantes > ion-col, .menu-lateral-actividades .actividades > ion-col, .menu-lateral-actividades .recursos > ion-col, .menu-lateral-actividades .acompañantes > ion-col, .menu-lateral-acompañantes .actividades > ion-col, .menu-lateral-acompañantes .recursos > ion-col, .menu-lateral-acompañantes .acompañantes > ion-col {
  padding-top: 15px;
}

.objetivos h4 {
  color: #50BD86;
}

.objetivo {
  margin-left: 27px;
}
.objetivo p, .objetivo span {
  margin: 3px 0;
}
.objetivo p.overline, .objetivo span.overline {
  color: #50BD86;
}
.objetivo ion-progress-bar {
  --background: #50BD864d;
  --progress-background: #50BD86;
  height: 8px;
  border-radius: 10px;
}

.actividades h4 {
  color: #9164CD;
}

.select-activities-caa ion-radio {
  --color: transparent;
  --color-checked: transparent;
}

ion-col .actividadCAA.actividadCAA {
  padding: 10px;
}
ion-col .actividadCAA.actividadCAA.selected {
  border: 3px solid #FF8533;
}

.fabAdd {
  position: absolute;
  right: 10px;
  bottom: 0px;
}

.actividad {
  border: 1px solid #AAAAA9;
  box-sizing: border-box;
  border-radius: 13px;
  margin-right: 10px;
  background-color: white;
  padding: 0 2rem 1rem 1.5rem;
}
.actividad .activity-text {
  width: 100%;
}

.h-3rem {
  height: 3rem;
}

.recursos h4 {
  color: #4AB3E5;
}

.acompañantes h4 {
  color: #434341;
}

.acompanyant img.photo {
  border: 1px solid gray;
}
.acompanyant .body2::first-letter {
  text-transform: uppercase;
}

.acompanyant-lateral ion-avatar {
  width: 63px;
  height: 63px;
}

.objetivos,
.acompañantes,
.actividades,
.recursos {
  display: flex;
}
.objetivos a,
.acompañantes a,
.actividades a,
.recursos a {
  color: #FF8533;
  margin-top: 16px;
  margin-bottom: 10px;
  margin-left: auto;
}

/******** MENU LATERAL BIBLIO ********/
.lateral-biblio h4 {
  margin: 0;
}
.lateral-biblio .actividades h4,
.lateral-biblio .objetivos h4,
.lateral-biblio .recursos h4 {
  color: #AAAAA9;
}

/**********   ESTILO GENERALES  *********/
.rowTitulo {
  min-height: 85px;
}

.lineal {
  display: inline;
}
.lineal button {
  margin: 0 5px 5px 0;
}

.lineal-flex {
  display: inline-flex !important;
}
.lineal-flex button {
  margin: 0 5px 5px 0;
}

.col-ajustada {
  width: auto;
}

ion-content {
  --offset-bottom: 0 !important;
}

ion-content[fullscreen=true] {
  background-color: white;
}

.progress {
  width: 75%;
  border-radius: 10px;
  background-color: rgba(80, 189, 134, 0.3019607843);
}
.progress .progress-bar {
  background-color: #50BD86;
}

ion-progress-bar {
  --background: #50BD864d;
  --progress-background: #50BD86;
  height: 8px;
  border-radius: 10px;
}

ion-col {
  padding-left: 0 !important;
}
ion-col.contImg {
  max-width: fit-content !important;
}
ion-col.contImg ion-icon {
  width: 30px;
  height: 30px;
}

ion-backdrop {
  background: rgba(0, 0, 0, 0.0784313725) !important;
}

.popoveCustom .popover-viewport.sc-ion-popover-md {
  margin-top: 0;
  margin-right: 0;
}

.popover-viewport.sc-ion-popover-md {
  border: 1px solid #999;
  padding: 10px 20px;
}

.popover-content {
  padding: 15px;
  overflow: visible !important;
}
.popover-content.sc-ion-popover-md {
  background: transparent;
}
.popover-content ion-label.sc-ion-select-popover {
  margin-right: 15px;
}
.popover-content a {
  font-weight: normal;
  cursor: pointer !important;
}
.popover-content p {
  padding-top: 5px;
  line-height: 42px;
}

[aria-checked=true].sc-ion-alert-md .alert-radio-icon.sc-ion-alert-md {
  border-color: #FF8533;
}

.alert-radio-inner.sc-ion-alert-md {
  background-color: #FF8533;
}

.alert-message h3 {
  color: black;
}

.select-text {
  font-size: 1.6rem;
}

ion-alert.select-alert.multiple-select-alert {
  --max-width:40vw;
}
ion-alert.select-alert .alert-wrapper {
  width: 50vw;
  --max-width:50vw;
  height: fit-content;
}
ion-alert.select-alert .alert-wrapper .alert-checkbox-group {
  --max-height:fit-content;
}
ion-alert.multiple-select-alert {
  --max-width: 700px;
  --width: 600px;
}
ion-alert .alert-checkbox-label {
  font-family: "Closer Font";
  white-space: break-spaces !important;
  font-weight: 500;
}
ion-alert .alert-wrapper {
  padding: 15px 20px;
  --max-width: 420px;
}
ion-alert .alert-button-group {
  justify-content: center !important;
}
ion-alert .alert-button-group button span {
  color: white;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.8rem;
}
ion-alert .alert-button-group button:nth-child(1) {
  background: #AAAAA9;
  border-radius: 21px;
  padding: 10px 25px;
}
ion-alert .alert-button-group button:nth-child(1):hover {
  background: #BDBDBD;
}
ion-alert .alert-button-group button:nth-child(1):focus {
  background: #E0E0E0;
}
ion-alert .alert-button-group button:nth-child(2) {
  background: #FF8533;
  border-radius: 21px;
  padding: 10px 25px;
}
ion-alert .alert-button-group button:nth-child(2):hover {
  background: #FFA970;
}
ion-alert .alert-button-group button:nth-child(2):focus {
  background: #FFC19F;
}
ion-alert [aria-checked=false] .alert-checkbox-label {
  color: #737373;
}

.marginElementos {
  margin-left: 25px;
}

button {
  background-color: transparent;
}

/**** HOME ****/
#nuevaPublicacion {
  background-color: white;
  border-radius: 12px;
  border: 2px solid #FF8533;
  padding: 5px 20px;
}
#nuevaPublicacion .inputTexto {
  border: 1px solid #E0E0E0;
  border-radius: 20px;
}
#nuevaPublicacion .nuevaPubliTop {
  border-bottom: 1px solid #E6E6E4;
}
#nuevaPublicacion .nuevaPubliBottom {
  padding-bottom: 10px;
}
#nuevaPublicacion .btn-enabled-principal {
  height: 4rem;
}
#nuevaPublicacion .btn-enabled-principal p {
  color: #FF8533;
}
#nuevaPublicacion .btn-disabled-principal {
  height: 4rem;
}
#nuevaPublicacion .btn-disabled-principal p {
  color: black;
}
#nuevaPublicacion p {
  color: #FF8533;
}

.publicar {
  background-color: #FF8533;
  color: white;
  border-radius: 21px;
}

.button-native {
  --background: transparent !important;
}

.split-left-btn {
  border-radius: 21px 0 0 21px !important;
  margin-right: 1px;
  padding: 10px 15px 10px 20px !important;
}

.split-right-btn {
  border-radius: 0 21px 21px 0 !important;
  padding: 0 13px !important;
}

.btn-filter-home {
  border: 1px solid #888888;
  padding: 5px 6px;
  border-radius: 5px;
}
.btn-filter-home.selected {
  background-color: #FF8533;
  padding: 5px 6px !important;
}

.btn-auxiliar {
  position: absolute;
  bottom: 45px;
  border: 1x solid black;
}

.btn-reacciones {
  position: absolute;
  right: 0;
  bottom: 100%;
}
.btn-reacciones.show {
  display: flex !important;
}

.btn-enabled-third {
  border: 1px solid #AAAAA9;
  background: white;
  --background: #FFFFFF;
  --box-shadow: none !important;
  --background-hover: #FFFFFF;
  border-radius: 21px;
  padding: 10px 25px;
  margin-top: 15px;
  display: none;
  width: fit-content;
  z-index: 99;
}
.btn-enabled-third p {
  color: #FF8533;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.8rem;
  margin-left: 0rem !important;
}
.btn-enabled-third.show {
  display: block;
}

.btn-enabled-principal {
  background: #FF8533;
  --background: #FF8533;
  --box-shadow: none !important;
  --background-hover: #FFA970;
  border-radius: 21px;
  padding: 10px 25px;
}
.btn-enabled-principal:hover {
  background: #FFA970;
  --background: #FFA970;
  --background-hover: #FFA970;
}
.btn-enabled-principal:focus {
  background: #FFC19F;
  --background: #FFC19F;
}
.btn-enabled-principal p {
  color: white !important;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.8rem;
  margin-left: 0rem !important;
}

.btn-disabled-principal {
  background: #C4C4C4;
  border-radius: 21px;
  padding: 10px 25px;
}
.btn-disabled-principal p {
  color: #7D7D7D;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.8rem;
}

.btn-enabled-secundario {
  background: #AAAAA9;
  border-radius: 21px;
  padding: 10px 25px;
  margin-top: 15px;
}
.btn-enabled-secundario:hover {
  background: #BDBDBD;
}
.btn-enabled-secundario:focus {
  background: #E0E0E0;
}
.btn-enabled-secundario p {
  color: white;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.8rem;
}

.btn-disabled-secundario {
  background: #C4C4C4;
  border-radius: 21px;
  padding: 10px 25px;
  margin-top: 15px;
}
.btn-disabled-secundario p {
  color: #7D7D7D;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.8rem;
}

.comentario ion-avatar {
  min-width: 65px;
  width: 65px;
}

#nuevoComentario .inputTexto {
  border: 1px solid #E0E0E0;
  border-radius: 20px;
}

.publicacion {
  background-color: white;
  border-radius: 12px;
}
.publicacion video {
  max-height: 45vh;
  background: black;
}
.publicacion p {
  user-select: text;
}
.publicacion .comentarios {
  padding-left: 5%;
}

.btns-title {
  height: 5vh;
}

.btn-naranja {
  height: 5vh;
  width: 5vh;
  background-color: #FF8533;
  border-radius: 5px;
  padding: 5px 0;
}

.borde-objetivo {
  border-bottom: 1px solid #50BD86;
}

.btn-objetivos {
  background-color: white;
  color: #AAAAA9;
  padding: 5px 10px;
  text-align: center;
  border: 1px solid #AAAAA9;
  border-radius: 21px;
}
.btn-objetivos.selected {
  background: #50BD86;
  border: none;
  color: white;
  padding: 5px 10px;
}
.btn-objetivos:hover:not(.selected) {
  background: #50BD86 !important;
  border-color: transparent;
  color: white;
  opacity: 0.55;
}
.btn-objetivos.titulo-seccion.selected {
  color: white;
  padding: 5px 10px;
}

.etapa p.text-titol:hover {
  color: #50BD86 !important;
}

.etapa {
  width: 100%;
}
.etapa p {
  color: black;
}
.etapa .status-0 {
  color: black;
}
.etapa .status-1 {
  font-weight: bold;
}
.etapa .borde-etapa {
  border-bottom: solid 1px #BDBDBD;
}
.etapa ion-progress-bar {
  width: 75%;
}

.etapa.border-gray {
  border-bottom: solid 1px #BDBDBD;
}

.interiorEtapa {
  padding-left: 25px;
}
ion-item {
  --border-radius: 5px;
  --border-color: #C4C4C4;
  --border-style: solid;
  --border-width: 1px;
  --background-hover: transparent;
  --background-focused: transparent;
  --highlight-color-focused: #FF8533;
  --highlight-color-valid:#FF8533;
  --highlight-color-invalid:#FF8533;
}
ion-item.objetivos-checkbox {
  --border-color: transparent;
  --background: transparent;
  --background-hover: transparent;
  --background-focused: transparent;
}

ion-select {
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
}
ion-select::part(text) {
  font-size: 1.5rem;
}

ion-checkbox {
  --background-checked: #FF8533;
  --border-color-checked: #FF8533;
}

ion-radio {
  --color-checked:#FF8533;
}

ion-radio-group ion-item {
  --background: transparent;
  --border-color: transparent;
}

ion-item.destacadoCheck {
  --background: transparent;
  --border-color: transparent;
}

ion-button {
  --background: transparent;
  --box-shadow: none;
}

ion-button p {
  font-family: "Closer Font";
  font-weight: 600;
  margin-left: 0 !important;
  size: 1.4rem !important;
  line-height: 1.8rem;
}
ion-button.saveBtn {
  color: white;
  --padding-top: 20px;
  --padding-end: 25px;
  --padding-bottom: 20px;
  --padding-start: 25px;
  --background: #FF8533;
  --border-radius: 21px;
  --box-shadow: none;
}
ion-button.cancelBtn {
  color: white;
  --padding-top: 20px;
  --padding-end: 25px;
  --padding-bottom: 20px;
  --padding-start: 25px;
  --background: #AAAAA9;
  --border-radius: 21px;
  --box-shadow: none;
}
ion-button.timelineBtn {
  --padding-top: 20px;
  --padding-end: 25px;
  --padding-bottom: 20px;
  --padding-start: 25px;
  --background: #fff;
  --border-radius: 21px;
  --border-style: solid;
  --border-width: 1px ;
  --border-color: #FF8533;
  --box-shadow: none;
  --color: #FF8533;
}
ion-button.imagenBtn {
  --padding-top: 20px;
  --padding-end: 25px;
  --padding-bottom: 20px;
  --padding-start: 25px;
  --background: transparent;
  --border-radius: 21px;
  --box-shadow: none;
  --border-style: solid;
  --border-color: #AAAAA9;
  --border-width: 1px;
}
ion-button.imagenBtn p {
  color: #AAAAA9;
}

.imagenBtn {
  margin-right: 10px;
  background: transparent;
}
.imagenBtn.btn-objetivos {
  justify-content: end;
}
.imagenBtn p {
  color: #AAAAA9;
}

.borde-actividad {
  border-bottom: 1px solid #9164CD;
}

.btn-new:hover {
  color: white !important;
}
.btn-new:hover p {
  color: white !important;
}

.btn-actividades {
  color: #AAAAA9;
  padding: 5px 10px;
  text-align: center;
  border: 1px solid #AAAAA9;
  border-radius: 21px;
}
.btn-actividades:hover:not(.selected) {
  background: #9164CD;
  border-color: transparent;
  color: white;
  opacity: 0.55;
}
.btn-actividades.selected {
  background: #9164CD;
  border-color: transparent;
  color: white;
  padding: 5px 10px;
}

.status-0 p {
  color: black !important;
}
.status-1 p {
  font-weight: bold;
}

.pendiente p {
  color: #AAAAA9 !important;
}

.terminada p {
  color: #9164CD !important;
}
.terminada .actividad {
  border-color: #9164CD !important;
}

ion-grid {
  --ion-grid-padding: 0px;
}

.fullSize {
  align-self: center;
}
.fullSize ion-col {
  align-items: center;
}
.fullSize h3 {
  color: black;
}
.fullSize .iconPrincipal {
  text-align: right;
}
.fullSize .iconPrincipal button {
  background-color: transparent;
}
.fullSize .iconPrincipal button ion-icon {
  height: 15vh;
  width: 15vh;
}
.fullSize > ion-grid {
  width: 70vw;
}
.fullSize ion-grid .usuaris ion-avatar.photo ion-img {
  border: 1px solid #AAAAA9;
}
.fullSize ion-grid .usuaris img {
  width: 70px;
  height: 70px;
  border-radius: 99px;
}
.fullSize ion-grid .usuaris h4 {
  margin: 0 10px;
}
.fullSize ion-grid .usuaris .usuari {
  border-top: 1px solid #FF8533;
}
.fullSize ion-grid .usuaris:last-of-type {
  border-bottom: 1px solid #FF8533;
}
.fullSize ion-router-link {
  --color: black;
}

/*******  RECURSOS *******/
.borde-recursos {
  border-bottom: 1px solid #4AB3E5;
}

.btn-recursos {
  color: #AAAAA9;
  padding: 5px 10px;
  text-align: center;
  border: 1px solid #AAAAA9;
  border-radius: 21px;
}
.btn-recursos:hover:not(.selected) {
  background: #4AB3E5 !important;
  border-color: transparent;
  color: white;
  opacity: 0.55;
}
.btn-recursos.selected {
  background: #4AB3E5;
  border: none;
  color: white;
  padding: 5px 10px;
}
.btn-recursos.titulo-seccion {
  margin: 0 5px 5px 0;
}
.btn-recursos.titulo-seccion.selected {
  color: white;
}

.recurso {
  border-bottom: 1px solid #BDBDBD;
}
.recurso.lastRecurso {
  border-bottom: none;
}

img.circleImg {
  border-radius: 99px;
  height: 5vh;
  width: 5vh;
}

img.circleImgGrande {
  border-radius: 99px;
  height: 65px;
  width: 65px;
}

/* PRINCIPAL */
.nuevoUsuario.circleImg, .nuevoUsuario.circleImgGrande,
.actividadGrupos.circleImg,
.actividadGrupos.circleImgGrande {
  border: 1px solid #AAAAA9;
  box-sizing: border-box;
  border-radius: 999px;
}
.nuevoUsuario .inputRedondeado,
.actividadGrupos .inputRedondeado {
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 16px;
}

.actividadGrupos {
  margin: 5vh 0;
}
.actividadGrupos .inputRedondeado {
  margin-left: 30px;
}
.actividadGrupos .publicacion {
  margin: 0 10%;
  margin-bottom: 2rem !important;
}

/* BIBLIOTECA */
.sc-ion-popover-md-h {
  --box-shadow: none !important;
}

.selected {
  padding-right: 20px;
}
.selected.cont-activid {
  border-bottom: 2px solid #9164CD;
}
.selected.cont-activid .h4 {
  color: #9164CD !important;
}
.selected.cont-obj {
  border-bottom: 2px solid #50BD86;
}
.selected.cont-obj .h4 {
  color: #50BD86 !important;
}
.selected.cont-recurso {
  border-bottom: 2px solid #4AB3E5;
}
.selected.cont-recurso .h4 {
  color: #4AB3E5 !important;
}

.selector-biblioteca {
  border-bottom: 2px solid #c4c4c4;
}

span.actividad {
  min-height: 80px;
}

.avatar-group {
  min-width: 64px;
}

ion-avatar .avatar-small {
  min-width: 55px;
  min-height: 54px;
  width: 55px;
  height: 54px;
}

.header ion-avatar {
  width: 4.5rem;
  height: 4.5rem;
  min-height: 45px;
  min-width: 45px;
}
.header img.photo {
  border: 2px solid white;
}

/* LOGIN */
.headerLogin {
  border-bottom: 1px solid #FF8533;
  margin: 10px;
  padding: 15px;
  padding-left: 0 !important;
  margin-left: 0 !important;
}

.squareBorder {
  background-color: #AAAAA9;
  height: 100%;
}

.separator {
  border-bottom: 1px solid #AAAAA9;
}

.border-bottom {
  border-bottom: 1px solid #BDBDBD;
}

.modal {
  display: block;
  position: fixed;
  z-index: 4;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}
.modal .item ion-label {
  text-overflow: unset;
  white-space: unset;
}
.modal ion-item {
  --border-style: none !important;
}
.modal.modal_notificaciones .modal_content {
  overflow: auto;
  width: 60vw;
  left: 20vw;
}
.modal.modal_photo .modal_content {
  position: relative;
  width: fit-content;
  margin: auto;
  top: 10vh;
  left: 0;
  height: 85vh;
}
.modal.modal_photo .modal_content img {
  height: 100%;
}
.modal.modal_activity_detail {
  z-index: 99999999;
}
.modal.modal_activity_detail .modal_content {
  min-height: 70vh;
  top: 15vh;
  width: 60vw;
  left: 20vw;
}
.modal.modal_aviso .modal_content {
  height: auto;
  overflow-y: hidden;
  background-color: #FFFFCC;
  border: 1px solid #AAAAA9;
  position: fixed;
  width: 80vw;
  left: 10vw;
  top: 5%;
}
.modal.modal_aviso .modal_content p {
  margin-left: 5vw;
}
.modal.modal_modify input {
  padding: 0 !important;
}
.modal.modal_modify .modal_content {
  overflow: hidden;
  width: 60vw;
  left: 20vw;
  padding: 55px;
}
.modal.modal_objetivos .modal_content {
  top: 10vh;
  width: 80vw;
  left: 10vw;
  height: fit-content;
}

.modal_content {
  background-color: white;
  position: absolute;
  min-height: 50vh;
  top: 25vh;
  overflow-y: scroll;
  width: 80vw;
  left: 10vw;
  padding: 20px;
  border-radius: 3px;
  border: 1px solid #C4C4C4;
}

.close {
  color: Black;
  float: right;
}

.close:hover {
  color: cyan;
  cursor: pointer;
}

.modal_copy .modal_content {
  left: 30vw;
  width: 40vw;
}

.modal_export .modal_content {
  top: 25vh;
  left: 30vw;
  width: 40vw;
}

.modal_import .modal_content {
  top: 25vh;
  left: 30vw;
  width: 40vw;
}

.modal_roles .roleRow {
  border-bottom: 1px solid #BDBDBD;
}

.modal_add ion-grid, .modal_add .modal_content {
  height: 30vh;
  top: 35vh;
  overflow: hidden;
  border-bottom: none !important;
}

.modal_roles .modal_content, .modal_add .modal_content {
  padding: 30px 55px;
  width: 50vw;
  left: 25vw;
}
.modal_roles .roleRow, .modal_add .roleRow {
  padding: 10px;
}
.modal_roles .roleRow .text-right, .modal_add .roleRow .text-right {
  text-align: right;
}
.modal_roles ion-item, .modal_add ion-item {
  border: 1px solid #C4C4C4;
  border-radius: 5px;
}

.invisible {
  display: none;
}

.tab-selected.tab-home {
  background-color: #FF8533;
}
.tab-selected.tab-home ion-icon {
  content: url("../img/iconos/home_selected.svg");
}
.tab-selected.tab-objetivos, .tab-selected.tab-biblio-objetivo {
  background-color: #50BD86;
}
.tab-selected.tab-objetivos ion-icon, .tab-selected.tab-biblio-objetivo ion-icon {
  content: url("../img/iconos/object_selected.svg");
}
.tab-selected.tab-actividades, .tab-selected.tab-biblio-actividad {
  background-color: #9164CD;
}
.tab-selected.tab-actividades ion-icon, .tab-selected.tab-biblio-actividad ion-icon {
  content: url("../img/iconos/activ_selected.svg");
}
.tab-selected.tab-recursos, .tab-selected.tab-biblio-recursos {
  background-color: #4AB3E5;
}
.tab-selected.tab-recursos ion-icon, .tab-selected.tab-biblio-recursos ion-icon {
  content: url("../img/iconos/resource_selected.svg");
}
.tab-selected.tab-acompañantes {
  background-color: #434341;
}
.tab-selected.tab-acompañantes ion-icon {
  content: url("../img/iconos/companion_selected.svg");
}

ion-tab-button ion-icon {
  font-size: 35px;
}

ion-tabs {
  display: contents !important;
}

.recuadroLogin {
  margin: 125px 0;
  background-color: white;
  border-radius: 16px;
}
.recuadroLogin .register-button {
  height: 47px;
  --background: #50BD86;
  background: #50BD86;
}
.recuadroLogin .register-button:hover {
  background: #6FCF97;
  --background: #6FCF97;
  --background-hover: #6FCF97;
}
.recuadroLogin .login-row {
  padding-bottom: 30px;
}
.recuadroLogin .login-row .login-button {
  margin-top: 38px;
  width: 100%;
  height: 47px;
}

.loginPage img, .loginPageMvl img {
  max-width: 40%;
}
.loginPage *, .loginPageMvl * {
  color: #434341;
}

.loginPageMvl {
  margin-top: 10vh;
}

div.commentItem {
  display: inline-flex;
  align-items: center;
}
div.commentItem .comment-icon {
  z-index: 999999;
  right: 5px;
}

.chincheta-home {
  height: 20px;
  width: 20px;
}

ion-item.commentItem, div.commentItem {
  margin-left: 5px;
  --border-style: none;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #E0E0E0;
}
ion-item.commentItem .comment-icon, div.commentItem .comment-icon {
  z-index: 999999;
  right: 5px;
}
ion-item.commentItem::part(native), div.commentItem::part(native) {
  height: fit-content;
  --min-height: none;
}

.accordion {
  cursor: pointer;
  padding: 10px;
  width: 100%;
  border: none !important;
  text-align: left;
  outline: none;
  transition: 0.4s;
  margin: 0;
}

.ml-auto {
  margin-left: auto;
}

.panel {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.panel.show {
  max-height: fit-content;
}
.panel .etapa-descripcion {
  margin: 15px 0;
}

.panel-interior {
  display: none;
}
.panel-interior.show {
  display: block;
}

.tr-absolute {
  margin: 5px;
  position: absolute;
  top: 0;
  right: 0;
  display: inline;
}

.contenidorEtapa {
  background-color: white;
  border-radius: 16px;
}

.achievement-logo {
  margin: -1px !important;
  border-radius: 8px 8px 0 0;
}
.achievement-logo .achievement-logo-width {
  width: 75%;
}

.objective-achievement {
  border: 3px solid #50BD86;
}
.objective-achievement.achievement-logo {
  background-color: #50BD86;
}

.resource-achievement {
  border: 3px solid #4AB3E5;
}
.resource-achievement.achievement-logo {
  background-color: #4AB3E5;
}

p.resource-achievement {
  border: none;
}
p.resource-achievement b {
  color: #4AB3E5;
  text-decoration: underline;
}
p.activity-achievement {
  border: none;
}
p.activity-achievement b {
  color: #9164CD;
  text-decoration: underline;
}
p.objective-achievement {
  border: none;
}
p.objective-achievement b {
  color: #50BD86;
  text-decoration: underline;
}

.activity-achievement {
  border: 3px solid #9164CD;
}
.activity-achievement.achievement-logo {
  background-color: #9164CD;
}

.dot {
  height: 25px;
  width: 25px;
  background-color: #ff8533;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  bottom: 1vh;
}

ion-row.etapa-titulo:last-child .vertical-line {
  display: none;
}

.vertical-line2 {
  width: 1px; /* Line width */
  background-color: #aaaaa9; /* Line color */
  left: 2rem;
  top: 5.3rem;
  position: absolute;
  bottom: -15px;
}
.vertical-line2.show {
  bottom: 0;
}

.cnt-input-name {
  width: 75%;
  margin: auto;
}

.interior img {
  align-self: start;
  position: relative;
  top: 5px;
}

.border-none {
  border: none !important;
}

.border-gris {
  border: 1px solid #AAAAA9;
}

.activity-clipped-text {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  background: transparent;
  position: initial;
}

.activity-clipped-text-small {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  background: transparent;
  position: initial;
}

.activity-clipped-text-1 {
  width: 100%;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  background: transparent;
  position: initial;
}

.group-selector img {
  visibility: hidden;
}
.group-selector.selected {
  color: #FF8533;
}
.group-selector.selected img {
  visibility: visible;
}

.edit-remove-container {
  position: absolute;
  bottom: 0;
}
.edit-remove-container ion-icon {
  margin: 0 0.5vh;
}
.edit-remove-container ion-icon.grande {
  min-height: 3rem;
  min-width: 3rem;
}

.cardEmptyElement .bg-white {
  border-radius: 16px;
}

.infoPost {
  background-color: white;
  border-radius: 25px;
  margin: 5px 10px;
}

:root {
  --ion-font-family: "Closer Font";
}

ion-toolbar .toolbar-container {
  height: 100%;
}
ion-toolbar.header button:first-child {
  align-items: center;
  width: 100%;
  display: inline-flex;
}
ion-toolbar.header button:first-child ion-avatar {
  margin: 5px;
}

.preview-image {
  max-width: 35%;
}

.preview-video {
  max-width: 45%;
}

.icon-accordion {
  width: 15px;
  height: 20px;
  margin-right: 2%;
}

.text-titol {
  width: 97%;
}

.text-descripcion {
  font-size: 1.4rem;
  line-height: 1.6rem;
}

.actividades-defecto-comunitecaa {
  min-height: 100%;
  background-color: #E0E0E0;
}
.actividades-defecto-comunitecaa .desktop-stiky {
  position: sticky;
  top: 0;
  z-index: 4;
}
.actividades-defecto-comunitecaa .actividadCAA {
  position: relative;
}
.actividades-defecto-comunitecaa .fabAdd {
  position: absolute;
  right: 20px;
  bottom: 20px;
  margin: 0;
  top: unset;
}
.actividades-defecto-comunitecaa > .container {
  width: 70%;
}
.actividades-defecto-comunitecaa .rowTitulo {
  padding-top: 15px;
}
.actividades-defecto-comunitecaa .btn-objetivos:not(.selected) {
  background-color: #F4F4F4 !important;
}
.actividades-defecto-comunitecaa ion-select {
  color: #9164CD;
  font-weight: bold;
}
.actividades-defecto-comunitecaa ion-select::part(icon) {
  opacity: 1;
}
.actividades-defecto-comunitecaa .cnt-vertical-line .vertical-line.show {
  height: 90%;
}

.cnt-detalle {
  margin-top: 10px;
  background-color: white;
  border-radius: 15px;
  padding: 15px 20px;
}

.btn-salir {
  padding: 5px 10px;
  width: fit-content;
  background: white;
  border: 1px solid #FF8533;
  border-radius: 20px;
}
.btn-salir p {
  color: #FF8533;
}

.cnt-recurso {
  min-height: 57px;
  margin: 5px 0;
  padding: 5px 10px;
  background-color: white;
  position: relative;
  width: 100%;
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
}
.cnt-recurso a {
  width: 85%;
}
.cnt-recurso ion-icon.grande {
  min-height: 2.5rem;
  min-width: 2.5rem;
}
.cnt-recurso .tr-absolute {
  align-items: center;
  height: -webkit-fill-available;
}

.menuLateral-recurso {
  width: 100%;
  display: flex;
  align-items: center;
}

.cnt-usuari {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.nuevoUsuario {
  position: relative;
}
.nuevoUsuario .btn-close {
  position: absolute;
  top: 0;
  right: 0;
}

.icons-vertical {
  justify-items: center;
  height: fit-content;
}
.icons-vertical ion-icon {
  margin-bottom: 5px;
}

.cnt-blanc {
  background-color: white;
  padding: 20px;
  border-radius: 20px;
}
.cnt-blanc .titol {
  border-bottom: 1px solid #FF8533;
  margin-bottom: 10px;
}

.modal_notificacion_aviso {
  z-index: 9999999999;
}

.aviso-naranja {
  color: #FF8533;
}

.small {
  font-size: 1.2rem;
}

.aviso-naranja.small {
  color: red;
  font-size: 1.2rem;
}

.cnt-admin-login {
  background: #ffffff;
  padding: 40px 40px 10px;
  border-radius: 4px;
  box-shadow: 0 -25px 37.7px 11.3px rgba(8, 143, 220, 0.07);
}

.form-group {
  margin-bottom: 1rem;
}
.form-group label {
  font-size: 13px;
  line-height: 1;
  vertical-align: top;
}

.popup-select p:hover {
  color: #FF8533;
}

.bigger-hover:hover {
  transform: scale(1.05);
}

.pag-principal .usuari:hover {
  background-color: rgba(67, 67, 65, 0.1294117647);
}

.boxActividad:hover {
  cursor: pointer;
  transform: scale(1.05) translate(0%);
}
.menu-lateral-acompañantes {
  border-bottom: none !important;
}

.no-wrapped {
  white-space: normal;
}

.infinite-loading {
  margin-top: 20px;
  margin-bottom: 20px;
}

.scrollable-content {
  height: calc(100vh - 68px);
  overflow: scroll;
  overflow-x: hidden;
}

.scrollable-content > .container {
  width: 70%;
}

.no-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.custom-btn {
  width: fit-content;
}

.custom-alert .custom-btn {
  margin-bottom: 5px;
}
.custom-alert .alert-object-too, .custom-alert .alert-activity {
  background: #FF8533;
  border-radius: 21px;
  padding: 10px 25px;
}
.custom-alert .alert-cancel {
  background: #AAAAA9;
  border-radius: 21px;
  padding: 10px 25px;
}
.custom-alert .alert-object-too:hover, .custom-alert .alert-activity:hover {
  background: #FFA970;
}
.custom-alert .alert-cancel:hover {
  background: #E0E0E0;
}
.custom-alert .alert-button-group {
  display: inline-flex;
  flex-direction: row;
}

