.header {
    background-color: #434341;
    height: 100%;
}

img {
    margin: 5px;
    vertical-align: middle;
}

.header h3 {
    font-size: 34px;
    vertical-align: middle;
    line-height: 45px;
    font-weight: 500;
    color: white;
    display: inline-block;
}

